// Light
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  src: url('../../assets/fonts/Montserrat-Light.ttf');
}

// Regular
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url('../../assets/fonts/Montserrat-Regular.ttf');
}

// Semibold
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  src: url('../../assets/fonts/Montserrat-SemiBold.ttf');
}

// Bold
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: url('../../assets/fonts/Montserrat-Bold.ttf');
}

body, p, span, a{
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  // line-height: 1.7;
  color: $color-black;
  font-size: 1.9rem;
}

.heading-primary{
  font-family: "Montserrat", sans-serif;
  font-size: 6rem;
  font-weight: 700;
  position: relative;

  &--line::after{
    content: "";
    width: 21rem;
    height: .8rem;
    background-image: url(../../assets/img/Vector_17.png);
    background-size: contain;
    background-position: left;
    position: absolute;
    left: 50%;
    bottom: -2rem;
    transform: translateX(-50%);
  }

  &--line-left::after{
    content: "";
    width: 21rem;
    height: .8rem;
    background-image: url(../../assets/img/Vector_17.png);
    background-size: contain;
    background-position: left;
    position: absolute;
    left: 0;
    bottom: -2rem;
  }

  &--white{
    color: $color-white;
  }

  &--banner{
    font-size: 6.4rem;
    color: $color-white;
    font-weight: 600;
  }
}

.heading-secondary{
  font-family: "Montserrat", sans-serif;
  font-size: 8rem;
  font-weight: 600;
  position: relative;

  &--line::after{
    content: "";
    width: 21rem;
    height: .8rem;
    background-image: url(../../assets/img/Vector_17.png);
    background-size: contain;
    background-position: left;
    position: absolute;
    left: 0;
    bottom: -2rem;
  }
  &--line-center::after{
    content: "";
    width: 21rem;
    height: .8rem;
    background-image: url(../../assets/img/Vector_17.png);
    background-size: contain;
    background-position: left;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -2rem;
  }

  &--white{
    color: $color-white;
  }
}

.heading-tertiary{
  font-family: "Montserrat", sans-serif;
  font-size: 5.6rem;
  font-weight: 600;

  &--white{
    color: $color-white;
  }
}

.heading-quaternary{
  font-family: "Montserrat", sans-serif;
  font-size: 4.8rem;
  font-weight: 600;

  &--white{
    color: $color-white;
  }

  &--header{
    font-size: 3.6rem;
    font-weight: 700;
  }
}

.heading-quinary{
  font-size: 3rem;
  line-height: 4rem;
  font-size: 2.4rem !important;

  &--white{
    color: $color-white;
  }
  &--features{
    font-size: 3.6rem !important;
    font-weight: 700;
    text-align: left;
  }
}
.paragraph{
  font-size: 3rem;
  line-height: 4rem;

  &--semiBold{
    font-weight: 600;
  }

  &--white{
    color: $color-white;
  }
  &--features{
    font-size: 3.6rem;
    font-weight: 700;
    text-align: center;
  }
  &--measures{
    font-weight: 600;
    text-align: center;
  }
  &--secondary{
    font-size: 1.8rem !important;
    font-weight: 700;
    text-align: center;
  }
  &--tertiary{
    font-size: 2.4rem !important;
    font-weight: 400;
  }
}
