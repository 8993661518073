.header{
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  max-height: 1440px;
  min-height: 860px;
  overflow: hidden;
  background-color: #0c0c0c;

  &::after {
    content: "";
    width: 100%;
    height: 100%;
    background: radial-gradient(100% 177.78% at 0% 0%, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.05) 100%);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  &__media-content{
    z-index: 1;
    position: absolute;
    width: 100%;
    height: 100%;

    .video-background-phone{
      display: none;
    }
    .video-background-desktop{
      display: block;
    }
    video{
      min-width: 100%;
      min-height: 100%;
      width: auto;
      height: auto;
      position: absolute;
      // top: 0;
      top: 50%;
      left: 0;
      transform: translate(0, -50%);
    }
  }

  &__tagline{
    z-index: 3;
    display: flex;
    align-items: center;
    position: absolute;
    font-size: 3.6rem;
    bottom: 6.5%;
    right: 3.5%;
    color: white;
  }

  &__text-box{
    display: flex;
    position: absolute;
    z-index: 4;
    top: 16%;
    left: 11.77%;
    align-items: flex-start;
    flex-direction: column;
    width: 35%;
    min-width: 680px;

    .heading-secondary{
      line-height: 113%;
    }
  }

  &__kickstarterBadge{
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 5;
    top: 18.82%;
    left: 26.06%;
    width: 20.7rem;
    height: 20.6rem;

    img{
      width: 100%;
      height: 100%;
    }
  }
}

.btn{
  display: flex;
  align-items: center;
  justify-content: center;

  &--countdown{
  width: 25.8rem;
  height: 5.767rem;
  margin-left: 40px;
  cursor: default !important;
  }

  &-group{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    :not(:last-child){
      margin-right: 1.6rem;
    }
  }

  &--order-now{
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    margin-top: 2rem;
    width: 212px !important;
    height: 61px !important;
    background-color: #2F80ED !important;
    border-radius: 12px !important;
    color: $color-white !important;
    font-size: 2.4rem !important;
    font-weight: 600 !important;
    transition: all cubic-bezier(0.68, -0.55, 0.265, 1.55) 0.6s !important;

    &:hover{
      background-color: #013f9b !important;
    }
  }

  &--amazon{
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    margin-top: 2rem;
    width: 212px !important;
    height: 61px !important;
    background-color: #FBA600 !important;
    border-radius: 12px !important;
    color: $color-white !important;
    font-size: 2.4rem !important;
    font-weight: 600 !important;
    transition: all cubic-bezier(0.68, -0.55, 0.265, 1.55) 0.6s !important;

    &:hover{
      color: #FBA600 !important;
      background-color: #000000 !important;
    }
  }

  &--kickstarter{
  width: 25.8rem;
  height: 5.767rem;
  background-image: url(../../assets/img/comingsoon.png);
  background-size: contain;
  margin-top: 20px;
  margin-left: 40px;
  background-repeat: no-repeat;
  background-position: center;
  cursor: default !important;

  &--black{
    background-image: url(../../assets/img/comingsoon-black.png);
  }
  }
}

.mini-gallery{
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  z-index: 5;
  max-width: 2030px;

  &-content {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    height: 300px;
    width: 100%;
    // background-color: #0dd2dc70;
    padding-left: 11%;
    padding-right: 11%;
  }

  &-use{
    position: absolute;
    font-family: Montserrat;
    font-weight: 700;
    font-size: 2.8rem;
    text-align: center;
    background: radial-gradient(102.98% 1561.24% at 0% 0%, #4468FF 0%, #1EAAE4 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    top: 0;
    left: 0;
    width: 100%;
    letter-spacing: 0.955em;
    // transform: translateX(-50%);
    z-index: 2;
    line-height: 120%;

    &--advice{
      color: white;
      letter-spacing: normal;
      font-size: 1.6rem;
      font-weight: 600;
      top: 88%;
      padding-left: 2.5rem;
      padding-right: 2.5rem;

      a{
        font-size: 1.6rem;
        font-weight: 800;
        text-decoration: underline #2F80ED 1px !important;
      }
    }
  }
}

.thumb-ctnr{
  // background-color: green; 
  width: 25%; 
  height:100%;
  padding-left: 12px; 
  padding-right: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.thumb{
  display: flex;
  width: 100%;
  padding-top: 57.14%;
  // background-color: pink;
  position: relative;
  border-radius: 12px;
  overflow: hidden;

  &::before{
    content: "";
    display: flex;
    width: 100%;
    height: 100%;
    background-color: #00000047;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  img{
    width: 102%;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__content{
    position: absolute;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 2.4rem;
    text-align: center;
    color: $color-white;
    bottom: 10%;
    left: 50%;
    width: 100%;
    transform: translateX(-50%);
    z-index: 2;
    line-height: 120%;
  }
}

.kickstarter-badge{
  position: absolute;
  bottom: 58%;
  left: 83%;
  z-index: 5;
}