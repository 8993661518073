#dashboard_container h6 {
  font-size: 20px;
  color: $color-blue-primary;
  margin-bottom: 15px;
}
.normalButton {
  background: #2f80ed;
  border: none;
  height: 30px;
  width: 130px;
  color: white;
  font-weight: bold;
  outline: none;
  cursor: pointer;
  font-size: 15px;
  outline: none;
  border-radius: 0.8rem;
}
#dashboard_container > div > div {
  margin-bottom: 15px;
}
.frame_product{
  &__frame_second-container{
    width: 60%;
    &__list{
      & > li{
          font-size: 18px;
      }
    }
  }
}
.forms_padding_fields {
  padding-right: 10px;
}
.forms-multiple_fields {
  width: 50%;
}
.btn_transparent {
  background-color: white;
  border: 1px solid #2f80ed;
  color: #2f80ed;
}
.frame {
  display: flex;
  border: 1px solid $color-blue-primary;
  width: 385px;
  align-items: center;
  margin-bottom: 15px;
  padding: 10px 0;
}
.frame_product > :first-child {
  width: 40%;
  padding-left: 15px;
}
#address_container,.dashboard_container__order-list {
  height: 300px;
  overflow-y: scroll;
}

.frame_product span {
  display: inline-block;
  height: 10px;
  width: 10px;
  background-color: #000;
}
.frame_address > :first-child {
  width: 80%;
  padding-left: 15px;
  cursor: pointer;
}
.frame_address > :last-child {
  width: 20%;
}
.frame_address button {
  border-radius: 100%;
  border: 1px solid $color-blue-primary;
  width: 27px;
  height: 27px;
  background: white;
  color: $color-blue-primary;
  outline: none !important;
  transition: all 0.3s;
}
.frame_address button:hover {
  background: #0893ae;
  color: #fff;
}
