.DemoPickerWrapper {
  padding: 0 12px;
  font-family: "Source Code Pro", monospace;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  border-radius: 3px;
  background: white;
  margin: 24px 0 48px;
  width: 100%;
}

.DemoPicker {
  font-size: 18px;
  border-radius: 3px;
  background-color: white;
  height: 48px;
  border: 0;
  width: 100%;
  color: #6772e5;
  outline: none;
  background: transparent;

  -webkit-appearance: none;
}

.Demo {
  background-color: #F7F7FB;
}

.StripeElement {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 40px;
  border: 1px solid #c8c8c8;
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
  background-color: white;
}

.StripeElement div{
  width: 100%;
}

.StripeElement + label{
  position: absolute;
  color: #2f80ed;
  top: 10px;
  left: 10px;
  font-weight: 600;
  pointer-events: none;
  transition: all 0.3s ease-in-out;
  opacity: 0;
  font-size: 15px;
}

.StripeElement--invalid{
  border: 1px solid red;
}
.StripeElement--complete{
  border: 1px solid #2f80ed;
}

.StripeElement--invalid + label{
  color: red;
  opacity: 1;
  transform: translate(0, -2em) scale(0.9, 0.9);
}
.StripeElement--complete + label{
  color: #2f80ed;
  opacity: 1;
  transform: translate(0, -2em) scale(0.9, 0.9);
}

.StripeElement--focus {
  border: 1px solid #2f80ed;
}
.StripeElement--focus.StripeElement--invalid{
  border: 1px solid red;
}

.StripeElement--focus.StripeElement--invalid + label{
  color: red;
}
.StripeElement--focus + label {
  opacity: 1;
  transform: translate(0, -2em) scale(0.9, 0.9);
}

.StripeElement.IdealBankElement,
.StripeElement.FpxBankElement,
.StripeElement.PaymentRequestButton {
  padding: 0;
}

.StripeElement.PaymentRequestButton {
  height: 40px;
}
