@mixin respond($breakpoint){
    @if $breakpoint == phablet {
      @media (max-width: 37.5em){@content}; //600px
    }
    @if $breakpoint == phone {
      @media (max-width: 34.375em){@content}; //550px
    }
    @if $breakpoint == sm-phone {
      @media (max-width: 25.875em){@content}; //414px
    }
    @if $breakpoint == tab-port {
      @media (max-width: 68.75em){@content}; //1100
    }
    @if $breakpoint == tab-port-mini {
      @media (max-width: 53.125em){@content}; //850
    }
    @if $breakpoint == tab-land {
      @media (max-width: 80em){@content}; // 1280
    }
    @if $breakpoint == medium-desktop {
      @media (max-width: 90em){@content}; //1440
    }
    @if $breakpoint == big-desktop {
      @media (max-width: 100em){@content};
    }
}
