.card-login_select-address{
  cursor: pointer;
}
#accordionCheckout{
  margin: 0 auto;
  width: 87%;
  & .card-custom {
    & button{
        padding: 0 15px;
        background: white;
        height: 54px;
        border:1px solid $color-blue-primary;
    }
  }
}
#accordionCheckout .collapsed strong::after{
  transform: rotate(180deg);
  top: -6px;
}
#forms_checkout{
  position: relative;
}
.accordionCheckout_element{
  display: flex;
  align-items: center;
  justify-content: space-between;
  &__paragraph{
    color:$color-blue-primary;
    & > strong{
      position: relative;
      &::after{
          content:url('../../assets/img/chevron-right.svg');
          position: absolute;
          transition: all 0.3s;
          transform: rotate(0deg);
          top:-4px;
      }
    }
  }
}
