.u-align-center {
  text-align: center;
}
.u-width-80 {
  width: 80% !important;
}
.u-width-60 {
  width: 60% !important;
}
.u-margin-left{
  margin-left: 1rem;
}
.u-margin-bottom-huge { margin-bottom: 20rem !important; }
.u-margin-bottom-big { margin-bottom: 10rem !important; }
.u-margin-bottom-semi-big { margin-bottom: 8rem !important; }
.u-margin-bottom-medium { margin-bottom: 4rem !important; }
.u-margin-bottom { margin-bottom: 2rem !important; }
.u-margin-bottom-small { margin-bottom: 1rem !important; }
.u-margin-bottom-br { margin-bottom: 1.5rem !important; }

.u-margin-top-huge { margin-top: 20rem !important; }
.u-margin-top-big { margin-top: 10rem !important; }
.u-margin-top-semi-big { margin-top: 8rem !important; }
.u-margin-top-medium { margin-top: 4rem !important; }
.u-margin-top-small { margin-top: 1rem !important; }
.u-margin-top { margin-top: 2rem !important; }

.u-medium{
  font-weight: 500;
}
.u-semiBold{
  font-weight: 600;
}
.u-bold{
  font-weight: 700;
}
.u-uppercase{
  text-transform: uppercase;
}
.display_mobile {
  display: none;
}