.braintree_input_container {
  height: 40px;
  border: 1px solid #c8c8c8;
  padding: 0 10px;
  width: 100%;
  background-color: white;
}
.braintree_input_container + label {
  position: absolute;
  color: #2f80ed;
  top: 9px;
  font-weight: 600;
  pointer-events: none;
  transition: all 0.5s;
  opacity: 0;
  font-size: 15px;
}
.braintree_input_container.braintree-hosted-fields-focused + label {
  opacity: 1;
  transform: translate(0, -2em) scale(0.9, 0.9);
}
.braintree_input_container.braintree-hosted-fields-focused.braintree-hosted-fields-invalid {
  border: 1px solid red;
}
.braintree_input_container.braintree-hosted-fields-focused.braintree-hosted-fields-invalid
  + label {
  color: red;
}
.braintree_input_container.braintree-hosted-fields-focused {
  border: 1px solid #2f80ed;
}
