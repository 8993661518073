.backer-form-data {
    width: 100%;
    max-width: 540px;
    margin-left: auto;
    margin-right: auto;
}

.rewardItemsCtnrDisable{
    position: relative;
    opacity: 0.8;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

.your-rewards-items {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 650px;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 2.4rem;
    border-bottom-width: 2px;
    border-bottom-color: #2F80ED;
    border-bottom-style: solid;
    margin-bottom: 2.4rem;

    img{
        width: 16rem;
        height: fit-content;
        transition: all cubic-bezier(0.68, -0.55, 0.265, 1.55) 0.3s;
    }

    &--disabled{
        border-bottom-color: #CACACA;
    }

}

.wristband {
    display: flex;
    flex-direction: column;

    &-color {

        display: flex;
        align-items: center;
        margin-top: 0.8rem;
        margin-bottom: 0.8rem;
        position: relative;

        input {
            width: 2.4rem;
            height: 2.4rem;
            opacity: 0;
            z-index: 1;

            &:checked + {
                .wristband-color-radio{
                    // background-color: #2F80ED;
                    border: 2px solid white;

                    &::after{
                        transform: translate(-50%, -50%) scale(1);
                        opacity: 1;
                        // background-color: aqua;
                    }
                }
              }
        }

        label {
            font-size: 1.8rem;
            font-weight: 600;
            margin-bottom: 0;
            margin-left: 10px;
        }

        &-radio{
            background-color: #ffffff;
            position: absolute;
            left: 0;
            top: 50%;
            // z-index: 0;
            width: 2.4rem;
            transform: translateY(-50%);
            height: 2.4rem;
            border-radius: 50%;
            border: 2px solid #BFBFBF;
            transition: all cubic-bezier(0.68, -0.55, 0.265, 1.55) 0.5s;

            &--disabled{

                &::after{

                    background-color: #CACACA !important;

                }

            }

            &::after{
                display: flex;
                align-items: center;
                justify-content: center;
                padding-top: 0.4rem;
                position: absolute;
                top: 50%;
                left: 50%;
                width: inherit;
                height: inherit;
                border-radius: 50%;
                content: url(../../assets/img/ant-design_check-circle-filled.svg);
                transform: translate(-50%, -50%) scale(0.6);
                transition: all cubic-bezier(0.68, -0.55, 0.265, 1.55) 0.5s;
                background-color: #2F80ED;
                opacity: 0;
            }
        }
    }
}