#table_car {
  border-collapse: collapse;
  width: 100%;
  margin-top: 30px;
}

#table_car tbody > tr {
  border-bottom: 1px solid #2f80ed;
  border-top: 1px solid #2f80ed;
  height: 70px;
  font-size: 20px;
}
#table_car tbody > tr > td {
  text-align: left;
  padding: 15px 0;
  font-size: 18px;
}
.delete_image_table {
  text-decoration: underline !important;
  font-weight: 600;
  color: #007bff !important;
  cursor: pointer;
}
#table_car tbody > tr > td p {
  border-bottom: 2px solid #000;
  width: 125px;
  font-size: 2.2rem;
  line-height: 1;
  margin-bottom: 20px;
}
#table_car thead > tr {
  font-size: 2rem;
  height: 60px;
}
#subtotal_container {
  display: flex;
  flex-direction: row-reverse;
}
#subtotal_container > table {
  text-align: right;
  font-size: 20px;
}
#subtotal_container > table > tbody > tr {
  height: 40px;
}
#subtotal_container > table > tbody > tr > td {
  width: 215px;
}
.button_container {
  display: flex;
  flex-direction: row-reverse;
  margin-top: 20px;
}
.button_container > ul {
  list-style: none;
}
.button_container > ul > li {
  display: inline-block;
}
.button_container > ul > li:last-child {
  margin-left: 10px;
}
#table_car img {
  width: 120px;
}
.button_container .btn_transparent {
  width: 180px;
}
.quantity_value{
  display: inline-block;
  width: 3.6rem !important;
  text-align: center;
}
.single-price{
  display: inline-block;
  width: 5.5rem;
}
.total_item{
  display: inline-block;
  width: 9.6rem;
}