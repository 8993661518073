.modal{
  z-index: 90000!important;
}

.explanation {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  z-index: 10000;
  position: relative;
  overflow: hidden;
  height: 100vh;
  min-height: 900px;
  max-height: 1080px;

  &::after {
    content: "";
    background-image: url(../../assets/img/covidcircle.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: right bottom;
    display: flex;
    width: 125%;
    height: 125%;
    position: absolute;
    bottom: -50%;
    right: -14%;
  }

  &__wrapper {
    display: flex;
    width: 75%;
    align-items: center;
    justify-content: flex-start;
  }

  &__text-box {
    margin-top: 100px;
    width: 55%;
    min-width: 300px;
    margin-right: auto;
    z-index: 100;
  }

  &__image-box {
    display: flex;
    justify-content: space-between;
    margin-top: 10.3rem;
    margin-bottom: 10.3rem;
    width: 100%;
  }

  &__image {
    width: 18rem;
    height: 18rem;
  }
}

.spraycare-features {
  display: flex;
  width: 100%;
  background-color: $color-smoke;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transform: translateY(-10.3%);
  box-sizing: content-box;
  overflow: hidden;
  position: relative;
  z-index: 2;
  background-color: #E8E8E8;

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    background-color: #E8E8E8;
  }

  &__text-box {
    width: 44%;
    margin-top: 8%;
    margin-bottom: 2%;

    .heading-primary {
      text-align: center;
      margin-bottom: 4rem;
    }

    .paragraph {
      text-align: center;
    }
  }

  &__image-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: relative;
  }

  &__image {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  &__line {
    position: absolute;
    z-index: 100;

    &--atomizer {
      right: 26.65%;
      width: 25.05%;
      top: 60.32%;
    }

    &--liquid {
      left: 24.68%;
      width: 15.52%;
      top: 42.53%;
    }

    &--level {
      left: 24.68%;
      width: 13.85%;
      top: 59.32%;
    }

    &--refill {
      right: 26.65%;
      width: 30.41%;
      top: 42.53%;
    }

    &--sensor {
      right: 26.65%;
      width: 19.01%;
      top: 14.27%;
    }

    &--charge {
      left: 24.68%;
      width: 23.59%;
      top: 14.27%;
    }
  }

  &__feature {
    display: flex;
    width: 9.89%;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &--atomizer {
      position: absolute;
      left: 71.35%;
      top: 66.05%;
    }

    &--liquid {
      position: absolute;
      right: 73.12%;
      top: 37.80%;
    }
    &--refill {
      position: absolute;
      left: 71.35%;
      top: 37.80%;
    }
    &--level {
      position: absolute;
      right: 73.12%;
      top: 66.05%;
    }

    &--sensor {
      position: absolute;
      left: 71.35%;
      top: 9.55%;
    }

    &--charge {
      position: absolute;
      right: 73.12%;
      top: 9.55%;
    }
  }

  &__features-box{
    width: 100%;
    flex-wrap: wrap;

    .spraycare-features__feature{
      width: 33% !important;
      padding-top: 5rem;
      justify-content: flex-start;

      .spraycare-features__text{
        width: 28rem;
      }
    }
    &--mobile-visible{
      display: none;
    }
  }

  &__img {
    width: 10rem;
  }

  &__text {
    width: 100%;
  }
}

.information {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  background-color: #1A1A1A;
  padding-top: 100px;

  // &::before{
  //   content: '';
  //   width: 100%;
  //   height: 20%;
  //   position: absolute;
  //   background-color: #1A1A1A;
  //   top: -18%;
  // }

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    max-width: 1440px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    z-index: 2;
    margin-bottom: 10rem;
  }

  &__text-box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 81.6rem;
    margin-top: 6rem;

    .heading-primary {
      text-align: center;
      margin-bottom: 4rem;
    }

    .paragraph {
      text-align: center;
    }
  }

  &__card-box {
    margin-top: 8rem;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 80%;
    height: auto;
  }

  &__card {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 48%;
    height: auto;

    &__img {
      margin-top: 6rem;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      position: relative;
      overflow: hidden;
      transition: all ease-in-out 0.4s;
      border-radius: 3.2rem;
      height: auto;

      img {
        width: 100%;
        height: auto;
      }
    }

    &--large{
      width: 80%;
      max-width: initial;
      margin-top: 8rem;


      img{
        width: 100%;
        overflow: hidden;
        border-radius: 3.2rem;
      }
    }
  }
}

.banner {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(180deg, #1A1A1A 50%, $color-white 50%);
  padding-top: 80px;
  padding-bottom: 80px;

  &__image-desktop{
    display: default;
  }
  &__image-mobile{
    display: none;
  }

  &__wrapper {
    width: 80%;
  }

  &__banner {
    display: flex;
    align-items: center;
    width: 100%;
    background-image: radial-gradient(100% 142.54% at 0% 0%, #0DD0DC 0%, #2F80ED 100%);
    border-radius: 3.2rem;
    position: relative;
    overflow: hidden;
  }

  &__image-box {
    display: flex;
    width: 100%;

    img {
      width: 100%;
    }
  }

  &__text-box {
    display: flex;
    flex-direction: column;
    width: 60%;
    top: 0;
    left: 0;
    padding-left: 6rem;
    padding-top: 6rem;
    position: absolute;

    .heading-primary{
    background-image: radial-gradient(100% 142.54% at 0% 0%, #008FBD 0%, #0051C9 100%);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    }
  }
}

.features {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: $color-white;

  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    // .features__content:last-child {
    //   margin-bottom: 20rem;
    // }

    .features__content:nth-child(odd) {
      flex-direction: row-reverse;
    }

    .features__content:first-of-type{
      .features__image-box{
        background-image: url(../../assets/img/SC-parts.png);
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
      }
    }
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  &__image-box {
    display: flex;
    align-items: center;
    width: 50%;
    padding-top: 50%;
    position: relative;
    overflow: hidden;

    img,video {
      position: absolute;
      width: 100%;
      height: auto;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &__text-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%;
    padding-left: 11.77%;
    padding-right: 11.77%;
    line-height: 1;

    .paragraph{
      margin-top: 6rem;
    }

    .heading-primary{
      font-weight: 600;
    }
    .btn{
    
      &--order-now{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 3rem;
        width: 20rem!important;
        height: 4.8rem !important;
        background-color: #2F80ED !important;
        border-radius: 1rem !important;
        font-size: 1.8rem !important;
      }
    }
  }
}

.buyNow{
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
  padding-top: 54.11%;
  background-image: url(../../assets/img/SprayCareShip.png), linear-gradient(0deg, rgb(0, 0, 0), rgb(0, 0, 0));
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;

  &__wrapper{
    display: flex;
    width:80%;
    align-items: flex-start;
    justify-content: center;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
    &__content-box{
    position: absolute;
    width: 50%;
    padding-top: 50%;
    z-index: 4;

    &::before{
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      background: rgba(0, 0, 0, 0.89);
      filter: blur(229px);
    }
  }
  &__text-box{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%);
    
    .heading-primary{
      text-align: center;

      &--line::after{
        left: 50%;
        bottom: -2rem;
        transform: translateX(-50%);
      }
    }
    .paragraph{
      text-align: center;
    }

    &--desktop{
      display: flex;
    }
    &--mobile{
      display: none;
    }
  }
  &__color-options{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-top: 35px;

    ul li {
      display: inline-block;
      height: 30px;
      width: 30px;
      border: 1px solid #bfbfbf;
      border-radius: 100%;

      &:not(:first-child){
        margin-left: 1.5rem;
      }

      &:nth-child(1){
        background-color: #000000;
      }
      &:nth-child(2){
        background-color: white;
      }
      &:nth-child(3){
        background-color: #0057b8;
      }
      &:nth-child(4){
        background-color: #EA8ABA;
      }
      &:nth-child(5){
        background-color: #575757;
      }
    }
  }

  &__image-box{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    position: relative;


  &--desktop{
    display: default;
  }
  &--mobile{
    display: none;
  }

    img{
    width: 100%;
    }
  }
}
.banner-features{
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  video{
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
  }

  #banrFeatVideoDektop{
    display: initial;
  }
  #banrFeatVideoTablet{
    display: none;
  }
  #banrFeatVideoMobile{
    display: none;
  }

  &::after{
    content: " ";
    display: flex;
    background-color: #00000080;
    width: 100%;
    height: 100%;
    position: absolute;
    top:0;
    left: 0;
  }
  .banner-features__content{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;

    .heading-secondary{
      text-align: center;
    }
  }
}
.size-comparison{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10rem;
  background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35)), url(../../assets/img/SC-Bg2.jpg),;
  background-position: bottom center;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;

  & > h1{
    margin-bottom: 6rem;
  }

  &__content{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 1920px;

  }
  &__item{
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &-ctnr {
      width: 50%;
      padding-top: 40%;
      position: relative;
    }

    &-title,
    &-img,
    &-height,
    &-width{
      position: absolute;
      transform-origin: center;
      transform: translate(-50%, -50%);
    }

    &-title {

      &--sprayCare{
        top: 10%;
        left: 72%;
      }
      &--smartwatch{
        top: 10%;
        left: 56%;
      }
    }

    &-img {
      width: 32%;

      &--sprayCare{
        top: 48%;
        left: 72%;
      }
      &--smartwatch{
        top: 48%;
        left: 56%;
      }
    }
    &-height {

      &--sprayCare{
        top: 44%;
        left: 40%;
      }
      &--smartwatch{
        top: 44%;
        left: 24%;
      }
    }
    &-width {

      &--sprayCare{
        top: 84%;
        left: 72%;
      }
      &--smartwatch{
        top: 84%;
        left: 56%;
      }
    }
    }
}

.critics{
  display: flex;
  // background-color: red;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  background-color: #F7F7FB;
  // overflow: hidden;

& > .paragraph,.heading-primary {
  max-width: 1478px;
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 10rem;
}
.paragraph,.heading-primary {
  text-align: center;
}

&-content{
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
  margin-top: 10rem;
  margin-bottom: 10rem;
  width: 100%;
  max-width: 1478px;
}
&-critic{
  display: flex;
  width: 25%;
  max-width:428px;
  min-width: 216px;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  &-img{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 40px;
    margin-bottom: 3rem;

    img{
      width: auto;
      height: 100%;
    }
  }

  .paragraph{
    font-size: 1.8rem;
    line-height: 180.9%;
  }

  .paragraph::after{
    content: attr(data-end);
    font-size: 3rem;
    font-weight: 500;
  }
  .paragraph::before{
    content: attr(data-start);
    font-size: 3rem;
    font-weight: 500;
  }
}
}

.reviews{
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding-top: 14rem;

  &--desktop{
    display: flex;
  }

  &--mobile{
    display: none;
  }

  .heading-primary{
    text-align: center;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  &-comments-ctnr{
    width: 100%;
    max-width: 862px;
    overflow: hidden;
    position: relative;

    &--desktop{
      display: flex;
      margin-top: 12rem;

      .custom-ratio-box{
        display: flex;
        width: 100%;
        padding-top: 69.373549884%;
      }
    }
    &--mobile{
      margin-top: 8rem;
      display: none;
    }
  }

  &-comment{
    display: flex;
    position: absolute;
    width: 100%;
    max-width: max-content;
    height: auto;

    img{
      width: 100%;
    }

    &--0{
      position: relative;
      max-width: 100%;
      height: initial;
    }
    &--1{
      left: 20%;
      top: 0;
    }
    &--2{
      left: 0%;
      top: 23.8%;
    }
    &--3{
      left: 42.5%;
      top: 14.5%;
    }
    &--4{
      left: 0%;
      top: 38%;
    }
    &--5{
      left: 38%;
      top: 49.8%;
    }
  }
}