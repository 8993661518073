*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Montserrat', Arial, Helvetica, sans-serif !important;
  list-style: none;
}
main{
  background-color: #fff;
}

html {
  font-size: 62.5%;

  @include respond(big-desktop) { // width < 1600
    .header{
      &__text-box{
        left: 6%;
      }
    }
    .mini-gallery{
      &-content {
        padding-left: 5%;
        padding-right: 5%;
      }
    }

    .thumb{

      &__content{
        font-size: 3rem ;
      }
    }
    .kickstarter-badge{
      bottom: 62%;
      left: 88%;
    }
  }

  @include respond(medium-desktop) { // width < 1440
    
    .kickstarter-badge{
      transform: scale(0.9);
    }
    .explanation {

      &::after {
        width: 110%;
        height: 110%;
        bottom: -50%;
        right: -25%;
      }

      &__text-box{
        margin-top: 150px;
      }
    }

    .size-comparison{
    
      &__content{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        max-width: 1920px;
    
      }
      &__item{
    
        &-ctnr {
          padding-top: 55%;
        }
    
    
        &-img {
          width: 45%;
        }
        &-height {
          .paragraph{
            &--measures{
              font-size: 2.8rem;
            }
          }
          &--sprayCare{
            top: 44%;
            left: 30%;
          }
          &--smartwatch{
            top: 44%;
            left: 15%;
          }
        }
        &-width {
          .paragraph{
            &--measures{
              font-size: 2.8rem;
            }
          }
        }
        }
    }
  }

  @include respond(tab-land) { // width < 1200
    font-size: 56.25%; //1rem = 9px, 9/16 = 56.25%
    .header{
      &__media-content{
        video{
          top: 20%;
          left: 20%;
          transform: translate(-20%, -20%);
        }
      }
      &__text-box{
        left: 8%;
      }
    }
    .mini-gallery{
      &-content {
        padding-left: 7%;
        padding-right: 7%;
      }
    }

    .kickstarter-badge{
      transform: scale(0.85);
      bottom: 48%;
      left: 84%;
    }
    .btn{
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 3rem;

      &--order-now{
        width: 182px !important;
        height: 48px !important;
        background-color: #2F80ED !important;
        border-radius: 12px !important;
        font-size: 2.2rem !important;
      }

      &--amazon{
        width: 182px !important;
        height: 48px !important;
        background-color: #FBA600 !important;
        border-radius: 12px !important;
        font-size: 2.2rem !important;

        img{
          height: 100%;
        }
      }
    }
    .spraycare-features {
      height: initial;
      min-height:1100px;
      max-height: initial;
   }
   .buyNow{
    background-size: cover;
    padding-top: 54.11%;
    }
  }

  @include respond(tab-port) { // width 1100
    
    font-size: 48%; //1rem = 8px, 8/16 = 50%

    .header{
      &__media-content{
        video{
          top: 20%;
          left: 20%;
          transform: translate(-20%, -20%);
        }
      }
      &__text-box{
        align-items: center;
        justify-content: center;
        left: 50%;
        transform: translateX(-50%);

        .heading-secondary{
          line-height: 113%;
          text-align: center;
          font-size: 9rem;
        }
        .paragraph{
          text-align: center;

        }
      }
    }
    .mini-gallery{
      &-content {
        padding-left: 7%;
        padding-right: 7%;
      }
    }

    .thumb{
      padding-top: 113.68%;

      img{
        width: auto;
        height: 100%;
        position: absolute;
      }

      &__content{
        bottom: initial;
        top: 50%;
        left: 50%;
        width: 100%;
        transform: translate(-50%, -50%);
        z-index: 2;
      }
    }

    .kickstarter-badge{
      transform: scale(0.85);
      bottom: 68%;
      left: 84%;
    }

    .spraycare-features{

      &__image{
        width: 165%;
      }
    }

    .explanation {

      &::after {
        width: 110%;
        height: 110%;
        bottom: -50%;
        right: -25%;
      }

      &__text-box {
        width: 100%;
        margin-top: 200px;

        .heading-tertiary{
          font-size: 8rem;
        }
      }
    }

    .information {
      &__wrapper {
        width: 100%;
      }
    }

    .banner{
      &__text-box {
        padding-left: 4.5rem;
        padding-top: 3.5rem;
        .heading-primary{
          &--banner{
            font-size: 5.2rem;
          }
        }
      }
    }
    .features{
      &__image-box{
        padding-top: 60%;
  
        img,video{
          width: auto;
          height: 100%;
        }
      }
      &__wrapper {
        .features__content:first-of-type{
          .features__image-box{
            img{
              transform: translateY(-50%);
              left: initial;
              right: -3%;
            }
          }
        }
        .spraycare-features__features-box {
          display: none;
          transform: initial;
          background-color: #EBEFF0;
        }
      }
    }
    .buyNow{
      padding-top: 60%;

      &__content-box{
        width: 100%;
      }

      &__wrapper{
        align-items: center;
      }
    }
}

  @include respond(tab-port-mini) { // width 850

    .header{
      &__media-content{
        video{
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
      &__text-box{
        width: 85%;
        min-width: initial;
        left: 50%;
        transform: translateX(-50%);

        .heading-secondary{
          line-height: 113%;
          text-align: center;
          font-size: 9rem;
        }
        .paragraph{
          text-align: center;

        }
      }
    }
    .mini-gallery{
      bottom: 3rem;

      &-content {
        height: 320px;
        padding-left: 4%;
        padding-right: 4%;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        align-content: center;
      }

      &-use{
        top: initial;
        bottom: calc(100% + 1rem);

        &--advice{
          // color: white;
          // letter-spacing: normal;
          // font-size: 1.6rem;
          // font-weight: 600;
          // top: 88%;
          bottom: -1.5rem;
          color: #FBA600;
    
          // a{
          //   font-size: 1.6rem;
          //   font-weight: 800;
          //   text-decoration: underline #2F80ED 1px !important;
          // }
        }
      }
    }

    .thumb-ctnr{
      width: 45%; 
      padding-left: 8px; 
      padding-right: 8px;
      padding-bottom: 16px; 
      justify-content: center;
      height:auto;
    }

    .thumb{
      padding-top: 45%;

      img{
        width: 100%;
        height: auto;
        position: absolute;
      }

      &__content{
        bottom: initial;
        top: 50%;
        left: 50%;
        width: 100%;
        transform: translate(-50%, -50%);
        z-index: 2;
      }
    }

    .kickstarter-badge{
      transform: translateX(-50%) scale(0.85);
      bottom: 26%;
      left: 50%;
    }
    .banner-features{
    
      &__content{
        .heading-secondary{
          font-size: 8rem;
          width: 80%;
          margin-left: auto;
          margin-right: auto;
        }
      }

      #banrFeatVideoDektop{
        display: none;
      }
      #banrFeatVideoTablet{
        display: initial;
      }
      #banrFeatVideoMobile{
        display: none;
      }
    }

    .heading-secondary {
      font-size: 6.5rem;
    }

    .paragraph {
      &--features {
        font-size: 3rem;
      }
    }

    .spraycare-features {
      // min-height:900px;
      transform: translateY(-12.2%);

      &__image {
        width: 160%;
      }

      &__feature {
        width: 20rem;
      }
    }
    .banner{
      &__wrapper {
        width: 90%;
      }
      &__text-box {
        width: 75%;
      }
      &__image-desktop{
        display: none;
      }
      &__image-mobile{
        display: flex;
        margin-bottom: 4.5rem;
      }
    }

    .information{
      &__card-box {
        width: 90%;
      }

      &__text-box{
        width: 85%;
      }
      &__card{
        &__img {
          border-radius: 1.5rem;
        }
        &--large{
          width: 100%;
          img{
            border-radius: 0;
          }
        }
      }
    }
    .features{
      &__content{
        flex-direction: column;
      }
      &__wrapper {
        .features__content:nth-child(odd) {
          flex-direction: column;
        }
        .features__content:first-of-type{
          .features__image-box{
              background-image: url(../../assets/img/SC-parts-medium.png);
            img{
              left: 50%;
              top: 50%;
              right: initial;
              transform: translate(-50%, -50%);
              transform: translate(-50%, -50%) scale(1.1)
            }
          }
        }
      }
      &__text-box{
        width: 100%;
        padding-top: 8rem;
        padding-bottom: 8rem;
        background-color: #EBEFF0;

        .paragraph{
          text-align: center;
        }
        
        .heading-primary{
          text-align: center;

          &--line-left::after{
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: -2rem;
          }
        }
        .btn{
          &--order-now{
            position: relative;
            left: 50%;
            transform: translateX(-50%);
          }
        }
      }
      &__image-box{
        padding-top: 100%;
        width: 100%;

        img{
          width: auto;
          height: 100%;
          transform: translate(-50%, -50%) scale(1.1)
        }
      }
    }
    .buyNow{
      padding-top: 120%;
    
      &__wrapper{
        width:80%;
      }
        &__content-box{
        width: 100%;
        padding-top: 100%;
      }
    
    }
    .explanation {

      &::after {
        width: 110%;
        height: 110%;
        bottom: -40%;
        right: -25%;
      }
    }
    .size-comparison{
    
      &__content{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        max-width: 1920px;
    
      }
      &__item{
    
        &-ctnr {
          padding-top: 80%;
        }
    
    
        &-img {
          width: 64%;
        }
        &-height {
          .paragraph{
            &--measures{
              font-size: 2.6rem;
            }
          }
          &--sprayCare{
            top: 39%;
            left: 12%;
            transform: rotate(270deg);
          }
          &--smartwatch{
            top: 39%;
            left: 0%;
            transform: rotate(270deg);
          }
        }
        &-width {
          .paragraph{
            &--measures{
              font-size: 2.6rem;
            }
          }
        }
        }
    }
    .critics{
      
    &-critic{
      width: 25%;
      min-width: 180px;
    
      &-img{
      height: 28px;
      }
    }
  }

  .reviews{
  
    &-comment{
      width: 60%;
    }
  }
}

  @include respond(phablet) { // width < 600

    .heading-quaternary {
      &--header {
        font-size: 5rem;
        font-weight: 700;
      }
    }
    .header {
      min-height: 880px;
      max-height: 1200px;

      &__tagline {
        right: auto;
      }
  &__text-box{
    width: 85%;
    top: 10%;
  }
  &__kickstarterBadge{
    width: 15.7rem;
    height: 15.6rem;
  }
  &__media-content{
    top: 0;
    video{
      top: 15%;
      left: 15%;
      transform: translate(-15%, -15%);
    }
  }
  }

  .banner-features{

    &__content{
      .heading-secondary{
        font-size: 8rem;
      }
    }
    
    #banrFeatVideoDektop{
      display: none;
    }
    #banrFeatVideoTablet{
      display: none;
    }
    #banrFeatVideoMobile{
      display: initial;
    }
  }

    .explanation {
      transform: initial;
      background-position: initial;
      background-size: initial;
      background-image: initial;
      background-color: $color-white;
      height: 100vh;

      &__text-box {
        margin-top: 100px;
        .heading-tertiary {
          margin-bottom: 10%;
        }
          .heading-tertiary{
            font-size: 6rem;
          }
      }

      &::after {
        content: "";
        background-size: 130%;
        background-position-x: -12%;
        background-position-y: 200%;
        display: flex;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
      }
    }

    .spraycare-features {
      transform: initial;
      justify-content: flex-start;
      min-height: 1024px;
      padding-bottom: 0rem;
      padding-top: 12%;
      overflow: hidden;

      &__wrapper {
        width: 100%;
        justify-content: flex-start;
        height: 100%;
        position: relative;
        overflow: hidden;
      }

      &__text-box {
        width: 80%;

        .heading-primary {
          text-align: center;
          margin-bottom: 4rem;
        }

        .paragraph {
          text-align: center;
        }
      }

      &__image-box {
        justify-content: flex-start;
        width: 100%;
        height: 75%;
        margin-top: 10%;
      }

      &__image {
        transform: translateX(0);
        width: 160%;
        overflow: hidden;
      }

      &__line {
        display: none;
      }

      &__feature {
        width: 180px;

        &--atomizer,
        &--liquid,
        &--level,
        &--sensor,
        &--charge {
          display: none;
        }
      }
      &__features-box{
        transform: translateY(-16%);
        &--mobile-visible{
          display: flex;
          padding-bottom: 10%;
        }
        .spraycare-features__text{
          width: 55% !important;
        }
      }
      &__img {
        width: 50%;
      }
    }

    .banner{
      box-sizing: border-box;

      &__wrapper{
        display: flex;
        width: 100%;
        max-width: initial;
        height: 100%;
        transform: initial;
      }

      &__banner{
        justify-content: space-between;
        flex-direction: column-reverse;
        width: 100%;
        border-radius: 0;
      }

      &__image-box{
        display: flex;
        width: 100%;
        max-width: initial;
        align-items: flex-end;
        justify-content: flex-end;

        img{
          padding-top: 30rem;
          width: 120%;
        }
      }
      &__text-box{
        display: flex;
        width: 100%;
        padding-left: 4rem;
        padding-right: 4rem;

        .paragraph{
          &--white{
            color: black;
            text-align: center;
          }
        }

        .heading-primary{
          &--banner{
            font-size: 6.5rem;
            text-align: center;
          }
        }
      }
    }
    .size-comparison{
    
      &__item{
    
        &-height {
          &--sprayCare{
            top: 38%;
            left: 8%;
          }
          &--smartwatch{
            top: 38%;
            left: -5%;
          }
        }
        }
    }

    .critics{
      &-content{
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-top: 0;
        margin-bottom: 10rem;
        width: 100%;
        max-width: 1478px;
      }
      &-critic{
        width: 85%;
        min-width: 180px;
        margin-top: 8rem;
        
        &-img{
        height: 28px;
        margin-bottom: 1.5rem;
        }
      }
    }
    .spraycare-modal {
      &-body{
        padding-top: 2.4rem;
        flex-direction: column;
      }

      &-header{
        padding-top: 0;
        text-align: center;
      }

      &-link{
        margin-top: 2.4rem;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.3);
        color: white;
        text-decoration: none !important;
        padding: 0.8rem;
        border-radius: 8px;
        font-size: 2.4rem;
        text-align: center;
      }
    }

    .spraycare-modal {
      &-body--blackfriday{
        padding-top: 10rem;
        flex-direction: column;
      }

      &-header--blackfriday{
        padding-top: 0;
        text-align: center;
      }

      &-link--blackfriday{
        margin-top: 2.4rem;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.3);
        color: white;
        text-decoration: none !important;
        padding: 0.8rem;
        border-radius: 8px;
        font-size: 2.4rem;
        text-align: center;
      }
    }
    .about{
      .imgPicMobile{
        display: initial;
      }
      .imgPicDesktop{
        display: none;
      }
    }
    #text_about > p {
      font-size: 24px;
      line-height: 30px;
    }

    #text_about {
      .aboutLink{
        font-size: 24px;
        line-height: 35px;
      }
    }
    .special_container {
      &--troubleshooting{
        padding: 100px 0;
      }
    }
  }

  @include respond(phone) { // width < 550
    .heading-quaternary{
      &--header{
        margin-bottom: -20px;
        font-size: 3rem;
        text-align: right;
      }
    }
    .header{
      min-height: 826px;
      max-height: 950px;

      &__tagline{
        width: 80%;
        // background-color: red;
        img{
          width: 3.5rem;
        }
      }

      &__text-box {
        margin-top: 0;
        min-width: initial;
        // width: 10%;

        .heading-secondary{
          line-height: 100%;
          font-size: 6rem;
        }
      }

      video{
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .mini-gallery{
      &-content{
        padding-left: 0;
        padding-right: 0;
      }
    }

    .thumb{
      padding-top: 50%;

      &-ctnr{
        width: 48%;
      }
    }

    .kickstarter-badge{
      bottom: 27%;
      transform: translateX(-50%) scale(0.7);
    }
    .explanation{
      min-height: 640px;
      max-height: 823px;

      &__wrapper {
        width: 90%;
      }
      &__text-box {
        margin-top: 15%;
      }
    }
    .paragraph{
      &--features{
        font-size: medium;
        line-height: 2.5rem;
      }
    }
    .information{
      &__card-box {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
      }
      &__card {
        margin-top: 8rem;
        width: 100%;
        &__content{
          width: 100%;
          padding-left: 0;
          padding-right: 0;
          .heading-quaternary{
            font-size: 3rem !important;
            margin-bottom: 2.2rem;
          }
          .paragraph{
            font-size: 2.2rem;
            font-weight: 400;
            line-height: 2.8rem;
          }
        }
        &__img{
          border-radius: 2.2rem;
        }
      }

      &__text-box {
        margin-top: 60px;
      }
    }

    .banner {
      &__text-box{
        padding-left: 2rem;
        padding-right: 2rem;

        .heading-primary{
          &--banner{
            font-size: 6.2rem;
          }
        }
      }
      &__image-box{
        display: flex;
        width: 100%;
        max-width: initial;
        align-items: flex-end;
        justify-content: flex-end;

        img{
          padding-top: 40rem;
          width: 150%;
        }
      }
    }
    .buyNow{
      padding-top: 90vh;
      background-image: url(../../assets/img/SprayCareShip.png), linear-gradient(90deg, #0E0E0E 0%, #131214 16.41%, #1A181A 39.62%, #181719 57.15%, #171717 72.98%, #0D1012 100%);
      background-size: 120%;
      background-position: center bottom;

      &__wrapper{
        width:80%;
        // background-color: #FBA600;
        align-items: flex-start;
      }
        &__content-box{
        width: 100%;
        margin-top: 10rem;
        padding-top: 100%;

        &::before{
          background: transparent;
        }
      }
    
    }

    .spraycare-features{
      &__image {
        transform: translateX(0);
        width: 180%;
        overflow: hidden;
      }
    }
    .size-comparison{
      & > h1{
        text-align: center;
        margin-bottom: 6rem;
      }
    
      &__content{
        flex-direction: column;
      }
      &__item{
        &-ctnr {
          width: 100%;
          padding-top: 120%;
          position: relative;
        }
    
        &-title {
    
          &--sprayCare{
            top: 10%;
            left: 50%;
          }
          &--smartwatch{
            top: 10%;
            left: 50%;
          }
        }
    
        &-img {
          width: 50%;
    
          &--sprayCare{
            top: 48%;
            left: 50%;
          }
          &--smartwatch{
            top: 48%;
            left: 50%;
          }
        }
        &-height {
    
          &--sprayCare{
            top: 39%;
            left: 0%;
            transform: rotate(270deg);
          }
          &--smartwatch{
            top: 39%;
            left: 0%;
            transform: rotate(270deg);
          }
        }
        &-width {
    
          &--sprayCare{
            top: 84%;
            left: 50%;
          }
          &--smartwatch{
            top: 84%;
            left: 50%;
          }
        }
        }
    }
    .reviews{

      &--desktop{
        display: none;
      }
    
      &--mobile{
        display: flex;
      }

      &-comments-ctnr{
    
        &--desktop{
          display: none;
        }
        &--mobile{
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    
      &-comment{
        width: 100%;
      }
    }
  }
  @include respond(sm-phone) { // width < 414
    .header{
      height: 100vh;
      min-height: 775px;
      &__text-box{
        .heading-secondary{
          font-size: 4.8rem;
        }
        .paragraph{
          font-size: 2.5rem;
          line-height: 3.4rem;
        }
      }
      &__media-content{
        top: 0;
        height: 100%;
        .video-background-phone{
          display: block;
        }
        .video-background-desktop{
          display: none;
        }
      }

      .btn-group{
        transform: scale(0.9);
      }
    }
    .mini-gallery{
      &-content{
        bottom: 0.5rem;
      }
    }
    .thumb{
      padding-top: 70%;

      img{
        width: auto;
        height: 100%;
        position: absolute;
      }

      &-ctnr{
        width: 48%;
        padding-left: 4px; 
        padding-right: 4px;
        padding-bottom: 8px; 
        justify-content: center;
        height:auto;
      }

      &__content{
        font-weight: 600;
        font-size: 2.5rem;
      }
    }
    .explanation{
      min-height: 640px;
      max-height: 823px;

      &__wrapper {
        width: 90%;
      }
      &__text-box {
        margin-top: 15%;
      }
        &::after {
          width: 100%;
          height: 100%;
          background-size: 130%;
          background-position: center 180%;
        }
    }
    .banner-features{

      &__content{
        .heading-secondary{
          width: 85%;
          font-size: 6.5rem;
        }
      }
      
      // #banrFeatVideoDektop{
      //   display: none;
      // }
      // #banrFeatVideoTablet{
      //   display: none;
      // }
      // #banrFeatVideoMobile{
      //   display: initial;
      // }
    }
    .spraycare-features{
      &__image {
        transform: translateX(0);
        width: 220%;
        overflow: hidden;
      }
    }
    .features {

      .features__content:first-of-type{
        .features__image-box{
            background-image: url(../../assets/img/SC-parts-small.png);
        }
      }

      &__wrapper {

        .spraycare-features__features-box {
          display: flex;
        }

      }
    }
    .size-comparison{
      &__item{
        &-ctnr {
          width: 100%;
          padding-top: 170%;
          position: relative;
        }
    
        &-title {
    
          &--sprayCare{
            top: 10%;
            left: 50%;
          }
          &--smartwatch{
            top: 10%;
            left: 50%;
          }
        }
    
        &-img {
          width: 65%;
    
          &--sprayCare{
            top: 48%;
            left: 68%;
          }
          &--smartwatch{
            top: 48%;
            left: 68%;
          }
        }
        &-height {
    
          &--sprayCare{
            top: 39%;
            left: 0%;
            transform: rotate(270deg);
          }
          &--smartwatch{
            top: 39%;
            left: 0%;
            transform: rotate(270deg);
          }
        }
        &-width {
    
          &--sprayCare{
            top: 84%;
            left: 68%;
          }
          &--smartwatch{
            top: 84%;
            left: 68%;
          }
        }
        }
    }
    .reviews{

      &-comment{
        width: 125%;

        &--0{
          position: relative;
          max-width: initial;
          height: initial;
        }
      }
    }
    }

    @media (max-width: 376px) {
      .explanation{
        max-height: 640px;
        min-height: 400px;

        &__wrapper {
          width: 90%;
        }
        &__text-box {
          margin-top: 2%;
        }
        &::after {
          width: 100%;
          height: 100%;
          background-size: 130%;
          background-position: center 270%;
        }
      }
      .spraycare-features{
        &__image {
          transform: translateX(0);
          width: 220%;
          overflow: hidden;
        }
      }
    }

    // @media (min-width: 2048px) {
    //   .header{
    //     &__text-box{
    //       // left: 50%;
    //       background-color: red;
    //     }
    //   }
    // }
}


body {
  box-sizing: border-box;
  scrollbar-color: #7f7f7f white;
  /* thumb and track color */
  scrollbar-width: thin;
  background: $color-smoke !important;
}

body h1,
body h2,
body h3,
body h4,
body h5,
body h6 {
  margin-bottom: 0;
  font-weight: bold;
}

body p,
body ul {
  margin-bottom: 0;
}

body button {
  outline: none !important;
}

hr {
  border-top: 1px solid #fff !important;
}

a {
  text-decoration: none !important;
}

.invalid{
  border:1px solid red!important;
}
.invalid+label::before{
  color:red!important;
}
.forms__text-invalidated{
  color:red;
  text-align: center;
  padding-bottom: 10px;
}
