@media (min-width: 2100px) {
}
@media (max-width: 1600px) {
  #producto_container > :last-child > div > p {
    font-size: 18px;
  }
  #producto_container > :last-child > div > h1 {
    font-size: 50px;
  }
  .frame {
    width: 300px;
  }
  .frame_product > :first-child {
    padding-left: 10px;
  }
  #checkout_container > div:first-child {
    padding-left: 0;
  }

  #checkout_container > :nth-child(2) {
    padding-right: 75px;
  }
}
@media (max-width: 1440px) {
  #payment_section ul {
    width: 180px;
  }
}
@media (max-width: 1400px) {
  .checkout_container-nav li {
    font-size: 20px;
  }
}

@media (max-width: 1200px) {
  .information_section_wrapper {
    /* background-color: blueviolet; */
  }
  .checkout_container-nav li {
    font-size: 15px;
  }
  .information-card-img {
    width: 300px;
  }
  .information-card_content {
    width: calc(300px - 4rem);
    transition: all ease-in-out 0.4s;
  }
  .information-card {
    margin-right: 5rem;
  }
  .information-card:hover .information-card_content {
    transform: translateX(calc(50% - 1rem));
  }
  .button_form_shipping_container .largeButtonOrder {
    font-size: 15px;
  }
  .shopping_car_container{
    width: 90%;
  }
}
@media (max-width: 1120px) {
  #car_item_checkout > div img {
    width: 100px;
    height: 50px;
  }
  #car_item_checkout > div i {
    top: -12px;
    left: 50px;
  }
  #explication_section > :first-child h3 {
    font-size: 30px;
  }
  #explication_section > :first-child > div > p {
    font-size: 15px;
  }
  .explication-container {
    width: 70%;
  }
  #images > div h4 {
    font-size: 20px;
  }

  #testimonials > :nth-child(1) > h1 {
    font-size: 40px;
  }
  #testimonials > :nth-child(2) {
    width: 70%;
  }

  #testimonials > :nth-child(1) > p {
    font-size: 20px;
  }
  .image_with_summary img {
    width: 230px;
  }
  .image_with_summary-content {
    width: 320px;
    left: -80px;
  }
  .image_with_summary-content > h2 {
    font-size: 20px;
  }
  .image_with_summary-content > span {
    font-size: 15px;
  }
  .information-card_content > h2 {
    font-size: 20px;
  }
  .information-card_content > span {
    font-size: 15px;
  }
  #absolute_moved {
    left: -120px;
  }
  .title_section > h2 {
    font-size: 18px;
  }
  .title_section > span {
    font-size: 22px;
  }
  #list_titles > ul > li {
    font-size: 25px;
  }
  #images > :nth-child(3) > h2 {
    width: 240px;
    line-height: 25px;
    font-size: 25px;
  }
  #clean_band img {
    width: 200px;
  }
  #clean_band > :last-child > h2 {
    font-size: 45px;
  }
  .clean_band-titles ul > li {
    font-size: 16px;
  }
  #clean_band > div {
    width: 65%;
  }
  #clean_band > div > :first-child {
    text-align: center;
  }
  .btn_order {
    margin-left: 10px;
  }

  #producto_container > :last-child > div{
    min-height: 450px;
  }
  #producto_container > :last-child > div > h1 {
    font-size: 40px;
  }
  #producto_container > :last-child > div > p {
    font-size: 13px;
    line-height: 20px;
  }
  #producto_container > :last-child > div > span {
    font-size: 20px;
  }
  .producto_container-reloj > ul > li > img {
    width: 55px;
  }
  .special_container {
    width: 65%;
  }
  .forms {
    padding-top: 30px;
  }
  .shopping_car_container{
    width: 95%;
  }
}
@media (max-width: 1000px) {
  #checkout_container {
    flex-wrap: wrap;
  }
  #checkout_container > div {
    width: 100%;
  }
  .button_form_shipping_container {
    margin-bottom: 100px;
    margin-top: 100px;
  }
  #checkout_container .display_normal{
    display: none;
  }
  #checkout_container .display_mobile{
    display: block;
  }
  #accordionCheckout .card-custom button::after{
    content:'';
  }
  .accessory{
    flex-direction: column-reverse;
    width: calc(33% - 0.5rem);
    min-width: initial;
    max-width: initial;
    height: 150px;
  }
  .accessory:nth-of-type(odd){
    margin-right: 0;
  }
  .accessory-data{
    flex-direction: column;
    width: 100%;
    height: 100%;
  }
  .accessory-radio{
    margin-right: 0;
    margin-top: 1rem;
    min-height: 24px;
    max-height: 24px;
  }
  .accessory-img{
    height: 80px;
    width: 80px;
    align-items: flex-start;
    justify-content: flex-start;
    margin-left: 0;
    position: relative;
  }
  .accessory-img img{
    height: 60px;
    width: auto;
  }
}
@media (max-width: 850px) {
  .explication-container {
    width: 80%;
  }
  #testimonials > :nth-child(2) {
    width: 80%;
  }
}
@media (max-width: 800px) {
  .information_section_wrapper {
    /* background-color: green; */
    flex-direction: column;
    align-items: center;
  }
  .information-card {
    margin-right: 0;
  }
  .information-card-img {
    width: 431px;
    height: 400px;
    display: flex;
    align-items: center;
  }
  .information-card:last-of-type {
    margin-top: 30px;
  }
  #producto_container > :last-child > div{
    min-height: 380px;
  }
}
@media (max-width: 768px) {
  #video_container {
    height: 50vh;
  }
  .explication_section-title_container > p {
    font-size: 20px;
  }
  .images_circles {
    flex-wrap: wrap;
  }

  .remove_mobile {
    display: none;
  }
  .contact_section__second-element {
    width: 100%;
  }
  .show_mobile {
    display: inherit;
  }
  .contact_section-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    width: 80%;
  }
  .mobile_view {
    margin-bottom: 50px;
  }
  .mobile_view > h1 {
    margin-bottom: 20px;
  }
  #contact_section .special_container {
    padding: 40px 0;
  }
  .special_container--contact > .forms{
    width: 90%;
  }
  .contact_section-list > li {
    width: 50%;
  }
  .images_circles > div {
    width: 50%;
    margin-bottom: 30px;
  }
  #contact_section {
    padding: 100px 0;
  }
  #testimonials > :nth-child(2) {
    flex-wrap: wrap;
  }
  #testimonials > :nth-child(2) > div {
    width: 50%;
    margin-bottom: 20px;
  }
  .information-card-img {
    height: 250px;
    display: flex;
    align-items: center;
  }
  #information_section::after {
    width: 70%;
    height: 600px;
  }
  .display_mobile {
    display: block;
  }
  .display_normal {
    display: none;
  }
  .image_with_summary img {
    width: 100%;
  }
  #information_section {
    height: 800px;
  }
  #images > :nth-child(1) {
    display: inherit;
  }
  #images > :nth-child(1) > :nth-child(1) {
    width: 100%;
  }
  #images > :nth-child(1) > :nth-child(2) {
    width: 100%;
    padding: 50px 75px;
  }
  #list_titles > ul > li {
    height: 22px;
    font-size: 20px;
  }
  #clean_band > div {
    display: inherit;
  }
  #clean_band > div > div {
    width: 100%;
  }
  .clean_band-button {
    text-align: center;
  }
  #clean_band > :last-child > h2 {
    text-align: center;
  }
  .responsive_class {
    opacity: 0;
    visibility: hidden;
    transition: all 0.5s ease-in-out;
  }
  .responsive_class.active {
    visibility: visible;
    transition: all 0.5 ease-in-out;
    opacity: 1;
  }

  .producto_container-reloj {
    margin: 0 50px;
  }
  #producto_container {
    display: inherit;
  }
  #producto_container > div {
    width: 100%;
  }
  #producto_container > :first-child {
    text-align: center;
  }
  #producto_container > :last-child {
    padding-top: 45px;
    padding-bottom: 20px;
    padding-left: 0;
    padding-right: 0;
  }
  .product-gallery {
    flex-direction: column;
  }
  .produc-photo img{
    border-radius: 2.4rem;
  }
  .product-gallery .produc-photo:nth-child(1){
    width: 100%;
    padding-right: 3rem;
    padding-left: 3rem;
    margin-left: initial;
  }
  .product-gallery .produc-photo:nth-child(2){
    width: 100%;
    margin-left: initial;
    margin-top: 3rem;
    padding-left: 3rem;
    padding-right: 3rem;
  }
  .product-gallery .produc-photo:nth-child(3){
    margin-top: 3rem;
    padding-left: 3rem;
    padding-right: 3rem;
    height: 280px;
    align-items: flex-start;
  }
  .product-gallery .produc-photo:nth-child(3) .product-text-box{
    z-index: 3;
    /* left: 10%; */
  }
  .product-gallery .produc-photo:nth-child(3) img{
    width: 100%;
    height: auto;
    object-fit: contain;
    z-index: 0;
  }
  .product-gallery .produc-photo:nth-child(3)::before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% - 6rem);
    height: 100%;
    margin-left: 3rem;
    background-color: black;
    border-radius: 2.4rem;
  }

  .producto_container-reloj {
    padding: 80px 0;
  }
  #producto_container > :last-child > div {
    margin: 0 auto;
    width: 70%;
    min-height: 300px;
  }
  .color_button_navbar {
    color: #fff;
    border: 1px solid #fff;
  }
  .special_container {
    width: 80%;
  }
  .special_container {
    padding: 140px 0;
  }
  #dashboard_container {
    display: inherit;
  }
  .frame {
    margin: 0 auto;
    width: 60%;
  }
  #table_car img {
    width: 120px;
  }
  #payment_section > div > div:nth-child(2) {
    padding-left: 20px;
    width: 100%;
  }
}
@media (max-width: 767px) {
  #checkout_container > :nth-child(2) {
    padding-right: 10px;
    padding-left: 10px;
  }
}

@media (max-width: 550px) {
  .survey-ctnr{
    padding-left: 2.4rem;
    padding-right: 2.4rem;
  }
  .button_form_shipping_container {
    margin-top: 90px;
    margin-bottom: 90px;
    justify-content: normal;
    flex-wrap: wrap;
  }
  #car_item_checkout > div img{
    width: 80px;
    height: 40px;
  }
  #car_item_checkout > div i{
    top: -16px;
    left: 37px;
  }
  .cost_container h1{
    font-size: 16px;
  }

  .cost_container{
    padding: 9px 0;
  }
  .cost_container h5{
    font-size: 16.5px;
  }
  .button_container ul{
    width: 100%;
  }
  .button_container > ul > li{
    display: block;
    margin-bottom: 10px;
  }
  #subtotal_container > table > tbody > tr > td{
    text-align: left;
    width: initial;
  }
  #subtotal_container{
    margin-top: 50px;
  }
  #subtotal_container > table{
    width: 100%;
  }

  .subtotal_container__td-left{
    text-align: left;
  }
  .textCenterMobile{
    text-align: center!important;
  }
  #table_car img{
    width: 120px;
  }
  #table_car tbody > tr > td p{
    margin-bottom: 10px;
  }
  .button_container > ul > li:last-child{
    margin-left: 0;
  }
  .button_container .normalButton{
    width: 100%;
  }
  .special_container.shopping_car_container{
    width: 90%;
  }
  .frame{
    margin-bottom: 15px;
    width: 100%;
  }
  .product-background-visible{
    display: none;
  }
  .button_form_shipping_container > div {
    width: 100%;
    margin-bottom: 10px;
  }
  #payment_section ul > li > img {
    width: 35px;
  }
  .dashboard_container__first_element{
      margin-bottom: 50px
  }
  #car_item_checkout > div > div:first-child {
    width: 30%;
  }
  .checkout_container-nav > ul {
    flex-wrap: wrap;
  }
  .checkout_container-nav > ul > li {
    margin-right: 0;
    width: 50%;
    margin-bottom: 10px;
    text-align: center;
  }
  .checkout_container-nav li::after {
    content: '';
  }
  .card-custom button::after {
    top: 35%;
  }
  #accordionQuestions {
    padding: 0 45px;
  }
  .background_ola {
    display: none;
  }
  .background_folder {
    display: none;
  }
  .video_subtitle {
    font-size: 20px;
  }
  .video_title {
    font-size: 25px;
  }
  .images_circles > div {
    width: 100%;
  }
  #clean_band > div > :last-child > h2 {
    font-size: 40px;
  }
  .explication_section-title_container > h1 {
    font-size: 40px;
  }
  .explication_section-paragraph {
    font-size: 10px;
  }
  #explication_section > :first-child h3 {
    font-size: 25px;
    text-align: center;
  }
  #clean_band {
    background-size: 100% 50%;
    background-position: 0 100%;
  }
  #explication_section > :first-child > div > p {
    font-size: 20px;
    width: 100%;
    text-align: center;
  }
  .image_explication {
    top: 70%;
  }
  #images > div h4 {
    width: 100%;
    text-align: center;
  }
  .explication_section-circles_information > div {
    height: 250px;
    width: 35%;
  }
  .explication_section-circles_information > div > h5 {
    font-size: 15px;
  }
  #images > div > div {
    width: 100%;
  }
  #images > div {
    flex-wrap: wrap;
  }
  .paddig_25 {
    padding-left: 0;
  }
  .information-card-img {
    width: 250px;
  }
  .withForImageSummary {
    width: 70%;
  }
  .image_with_summary-content {
    left: -40px;
  }
  #list_titles > ul > li {
    height: 15px;
    font-size: 15px;
  }
  #images > :nth-child(3) > h2 {
    width: 105px;
    line-height: 15px;
    font-size: 12px;
  }
  #images > :nth-child(1) > :nth-child(2) > h4 {
    text-align: center;
  }
  .clean_band-titles {
    justify-content: space-between;
  }
  #producto_container > :first-child {
    padding-left: 0;
  }
  #producto_container > :last-child {
    padding-top: 0;
    padding-left: 0;
  }
  #producto_container > :last-child > div {
    width: 100%;
    padding-left: 3rem;
    padding-right: 3rem;
  }
  .producto_container-reloj > ul > li > img {
    width: 50px;
  }
  .special_container {
    width: 100%;
  }
  .special_container > .troubleshooting{
    font-size: 4.6rem;
  }
  .special_container--contact > .forms{
    width: 95%;
  }
  .forms {
    width: 70%;
  }
  #dashboard_container > div {
    padding: 0 15px;
  }
  .frame {
    width: 100%;
  }
  .forms-multiple_fields {
    width: 100%;
    margin-bottom: 30px;
  }
  .forms_padding_fields {
    padding-right: 0;
  }
  .forms > div {
    display: inherit;
  }
  .table_container {
    overflow-x: scroll;
  }
  .information-card_content {
    padding-left: 10px;
  }
  #testimonials > :nth-child(2) > div > h2 {
    font-size: 15px;
  }
  #testimonials > :nth-child(2) > div > p {
    font-size: 12px;
  }
  #text_about {
    margin: 24px 24px;
  }
  .producto_container-reloj {
    margin: 0 3rem;
  }
}
@media (max-width: 440px) {
  #forms_checkout h4, .forms_checkout-information > h3{
    font-size: 16px;
  }

  .u-sm-device-visible{
    display: table-cell;
  }
  .u-sm-device-hide{
    display: none;
  }
  .tabs_card {
    flex-direction: column;
  }
  .tabs_card div{
    margin-top: 8px;
    margin-bottom: 8px;
  }
  .accessory{
    flex-direction: row;
    width: calc(50% - 0.5rem);
    height: 110px;
  }
  .accessory:nth-of-type(odd){
    margin-right: 0.5rem;
  }
  .accessory-data{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }
  .accessory-radio{
    margin-top: 0rem;
  }
  .accessory-img{
    height: 50px;
    width: 50px;
    align-items: flex-start;
    justify-content: flex-start;
    margin-left: 0;
    position: relative;
  }
  .accessory-img img{
    height: 50px;
    width: auto;
  }
}
@media (max-width: 414px) {
  #testimonials > :nth-child(2) > div {
    width: 100%;
  }
  .tabs_checkout__first-child{
    width: 100%!important;
  }
  .tabs_checkout > div p{
    font-size: 10px;
    text-align: right;
  }

  .tabs_checkout > div{
    flex-wrap: wrap;
  }
  #payment_section > div > div:nth-child(2){
    width: 90%;
  }
}
@media (max-width: 375px) {
  .card-login_text-container > p {
    font-size: 13px;
  }
  .accessory-name{
    font-size: 1.5rem;
  }
  .accessory-name span{
    font-size: 1.5rem;
  }
  .information_section_wrapper {
    max-width: none;
    min-width: 0;
  }
  #images {
    width: 85%;
  }
  #clean_band > :last-child > h2 {
    font-size: 40px;
  }
  .clean_band-titles {
    display: inherit;
  }
  .clean_band-titles > ul {
    padding-left: 20px;
  }
  #payment_section > div > div:first-child img{
    width: 20px;
  }
  .product-gallery .produc-photo:nth-child(2) .product-text-box{
    left: 6rem;
  }
  .product-gallery .produc-photo:nth-child(3) .product-text-box{
    left: 6rem;
  }
}
@media (max-width: 335px) {
  .accordionCheckout_element__paragraph > strong{
    font-size: 12px;
  }
}
