//COLORS
$color-blue-primary: #2F80ED;
$color-blue-secondary: #0DD0DC;
$color-smoke: #F7F7FB;
$color-dark: #272C31;
$color-white: #ffffff;
$color-black: #000000;

//CARD
$card-shadow: 0 1.5rem 4rem rgba($color-black, .15);

//FONT
$default-font-size: 1.6rem;

//TRANSITION
$default-transition-time: All ease-in-out .3s;

//UTILITIES
$margin-bottom-huge: 20rem;
$margin-bottom-semi-huge: 15rem;
$margin-bottom-big: 10rem;
$margin-bottom-semi-big: 8rem;
$margin-bottom-medium: 4rem;
$margin-bottom: 2rem;
$margin-bottom-small: 1rem;

$margin-top-huge: 20rem;
$margin-top-semi-huge: 15rem;
$margin-top-big: 10rem;
$margin-top-semi-big: 8rem;
$margin-top-medium: 4rem;
$margin-top-small: 1rem;
$margin-top: 2rem;