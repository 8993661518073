.display_mobile {
  display: none;
}

.spraycare-modal {
  display: flex;
  width: 100%;
  max-width: 72.8rem;
  min-width: 20rem;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
.close-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4.2rem;
  height: 4.2rem;
  position: absolute;
  right: 2.4rem;
  top: 5%;
  border: none;
  background: none;
  outline: none;
  color: black;
  opacity: 1;
  font-weight: 600;
  font-size: 1.2rem;
}
.close-btn:hover {
  opacity: 1;
}
.spraycare-modal-full-screen-ctnr {
  width: 100%;
  height: 100vh;
  padding-left: 2.4rem;
  padding-right: 2.4rem;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 50002;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  transition: all ease-in;
  animation: backgroundAppear 0.6s forwards;
  overflow: hidden;
}
.spraycare-modal-full-screen-ctnr--display {
  display: flex;
}
.spraycare-modal-full-screen-ctnr--none {
  display: none;
}
.spraycare-modal-body {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  background-color: #EDB82F;
  border-radius: 1.6rem;
  padding-left: 2.4rem;
  padding-right: 2.4rem;
  opacity: 1;
  transform: translateY(50%);
  position: relative;
  animation: modalAppear 0.6s forwards;
  animation-delay: 0.3s;
}

.spraycare-modal-body--blackfriday {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  background-color: #EB0046;
  border-radius: 1.6rem;
  padding-left: 2.4rem;
  padding-right: 2.4rem;
  opacity: 1;
  transform: translateY(50%);
  position: relative;
  animation: modalAppear 0.6s forwards;
  animation-delay: 0.3s;
  padding-top: 2.4rem;
  padding-bottom: 2.4rem;
}

.spraycare-modal-icon {
  display: flex;
  width: 100%;
  height: 100%;
  max-height: 100px;
  max-width: 100px;
  min-height: 50px;
  min-width: 50px;
  margin-right: 1.5rem;
}
.spraycare-modal-icon--backfriday {
  display: flex;
  width: 100%;
  height: 100%;
  max-height: 105px;
  max-width: 418px;
  min-height: 50px;
  min-width: 50px;
  margin-right: 1.5rem;
}

.spraycare-modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  padding-top: 4.2rem;
  padding-bottom: 4.2rem;
  font-size: 2.6rem;
  font-weight: 600;
  color: black;
  line-height: 30.5px;
}

.spraycare-modal-header--backfriday {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  flex-direction: column;
  width: 100%;
  padding-top: 8.4rem;
  padding-bottom: 1rem;
  font-size: 2.6rem;
  font-weight: 600;
  color: black;
  line-height: 30.5px;
}
.spraycare-modal-link {
  font-size: 1.8rem;
  text-decoration: underline 2px currentcolor !important;
  font-weight: 600;
  color: black;
  line-height: 30.5px;
}

.spraycare-modal-link--blackfriday {
  font-size: 2.4rem;
  text-decoration: underline 2px currentcolor !important;
  font-weight: 700;
  color: white;
  line-height: 30.5px;
}

.spraycare-modal-link:hover {
  color: white;
}
.spraycare-modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.spraycare-modal-actions {
  display: flex;
  flex-direction: column;
  padding-top: 2.4rem;
  padding-bottom: 2.4rem;
  align-items: center;
}

@keyframes backgroundAppear {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
@keyframes modalAppear {
  0% {
    opacity: 0;
    transform: translateY(50%);
  }
  50% {
    opacity: 0.5;
    transform: translateY(-10%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.u-sm-device-visible{
  display: none;
}
.u-sm-device-hide{
  display: default;
}

.pre_order {
  border: none;
  border-radius: 10px;
  width: 100px;
  height: 30px;
  color: #fff;
  background-color: #2f80ed;
  font-size: 14px;
}
body select {
  outline: none;
}
/* #root{
  background-color: #0c1f35 !important;
} */
#title_video {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 440px;
  transform: translate(-50%, -50%);
  text-align: center;
}
#title_video .btn_order {
  margin-left: 0;
}
#title_video > h1 {
  color: #fff;
  margin-bottom: 120px;
  font-size: 40px;
  letter-spacing: 2px;
}
.information-card:last-of-type {
  margin-right: 0;
  margin-top: 300px;
}
.image_with_summary-content {
  position: absolute;
  width: 415px;
  bottom: 35px;
  left: -110px;
}
.withForImageSummary {
  margin: 0 auto;
  width: 40%;
}
#absolute_moved {
  position: absolute;
  left: -155px;
  bottom: 300px;
}

#images {
  margin: 0 auto;
  width: 100%;
}

.image_with_summary-content > h2 {
  margin-bottom: 10px;
  font-size: 30px;
}
.image_with_summary-content > span {
  font-weight: 100;
  font-size: 23px;
}

#images > div {
  display: flex;
  background-color: #fff;
}
#images > div > div {
  width: 50%;
}
#images > :nth-child(1) {
  background: radial-gradient(100% 142.54% at 0% 0%, #0dd0dc 0%, #2f80ed 100%);
  margin-bottom: 150px;
  border-radius: 50px;
}
#images > :nth-child(1) h4 {
  color: #fff;
}
#images > :nth-child(1) img {
  width: 60%;
  height: auto;
}
#images > :nth-child(1) > :nth-child(1) {
  width: 60%;
  padding: 0px;
  text-align: center;
}

#images > :nth-child(1) > :nth-child(2) {
  width: 40%;
  color: #fff;
}
#images > div h4 {
  font-size: 44px;
  font-weight: 600;
  margin-bottom: 2rem;
  /*transform: translateX(-30px);*/
  color: #000;
  width: 100%;
}
.line_image {
  position: relative;
}

.line_image--discount {
  text-decoration-line: line-through;
  -webkit-text-decoration-line: line-through;
  text-decoration-color: #797979;
  -webkit-text-decoration-color: #797979;
  text-decoration-thickness: 2px;
  color: #797979 !important;
  font-size: large !important;
}
.line_image::before {
  content: url('../assets/img/Vector_17.png');
  position: absolute;
  bottom: -40px;
  left: 0;
}
.disabled {
  background-color: #8080806b !important;
}
.color_selected {
  position: absolute;
  opacity: 0;
  width: 25px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.paddig_25 {
  padding-left: 25%;
}
#images > :nth-child(1) > :nth-child(2) > ul > li {
  position: relative;
  margin-bottom: 30px;
  font-size: 20px;
  padding-left: 15px;
  font-weight: 100;
}

#images > div > div {
  position: relative;
  width: 50%;
  display: flex;
  align-items: center;
  padding: 25px;
}
#images > div > div > img {
  height: 100%;
  width: 100%;
}
#images > :nth-child(3) {
  position: relative;
  width: 100%;
}
#images > :nth-child(3) > img {
  width: 100%;
}
#images > :nth-child(3) > h2 {
  position: absolute;
  top: 15%;
  width: 360px;
  font-size: 40px;
  right: 11%;
  color: #fff;
  text-align: center;
  line-height: 37px;
}
#list_titles {
  position: absolute;
  left: 20px;
  top: 20px;
  width: 100%;
}
#list_titles > ul > li {
  color: #fff;
  font-size: 45px;
  font-weight: bold;
  height: 40px;
}
#clean_band {
  background-color: #fff;
  background: url('../assets/img/Group_120.png') no-repeat;
  background-size: 100% 100%;
  background-position: 0 0;

  /*background: radial-gradient(50% 89.02% at 50% 50%, #777777 0%, #292929 100%);*/
}
#clean_band > div {
  position: relative;
  display: flex;
  padding: 0 0;
  margin: 0 auto;
  width: 70%;
}

#clean_band img {
  width: 100%;
}
#clean_band > div > div {
  width: 50%;
  color: #fff;
}
#clean_band > div > :last-child > h2 {
  font-size: 60px;
  color: #000;
  margin-bottom: 55px;
  margin-top: 75px;
}
#clean_band > div > :last-child > p {
  font-size: 18px;
  color: #000;
  font-weight: bold;
  margin-bottom: 17%;
}
.clean_band-titles {
  display: flex;
  font-weight: 100;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 30px;
}
.clean_band-colors {
  margin-top: 35px;
}
.clean_band-colors > h1 {
  margin-bottom: 10px;
}
.clean_band-colors > ul > li {
  display: inline-block;
  height: 30px;
  width: 30px;
  border: 1px solid #bfbfbf;
  border-radius: 100%;
}
.clean_band-titles ul > li {
  margin-bottom: 20px;
  font-size: 20px;
  position: relative;
}
.clean_band-colors ul > li:nth-child(1) {
  background-color: #000;
}

.clean_band-colors ul > li:nth-child(2) {
  background-color: #5b5b5b;
}

.clean_band-colors ul > li:nth-child(3) {
  background-color: #ff7146;
}
.clean_band-button > span {
  font-size: 20px;
  font-weight: bold;
}
.btn_order {
  background: radial-gradient(100% 142.54% at 0% 0%, #0dd0dc 0%, #2f80ed 100%);
  /*background-color: #0893ae;*/
  outline: none;
  border: none;
  width: 140px;
  font-size: 12px;
  height: 40px;
  cursor: pointer;
  color: #fff;
}
.icon {
  position: absolute;
  width: 25px !important;
  left: -30px;
  top: 0px;
}

.icon.forIcon {
  width: 15px !important;
}

.icon_special {
  left: -23px;
  top: 4px;
}
#carouselExampleIndicators .carousel-inner{
  border-radius: 1.6rem;
}
#producto_container {
  display: flex;
  position: relative;
  width: 100%;
  max-width: 1174px;
  margin-left: auto;
  margin-right: auto;
}
.product-background-visible{
  display: initial;
}
#producto_container > div {
  width: 50%;
}

#producto_container > :first-child {
  position: relative;
  text-align: right;
  /* height: 1080px; */
}
.producto_container-background {
  position: absolute;
  left: 0;
  width: 267px;
  top: 0;
  height: 100%;
  z-index: -1;
}
#producto_container > :last-child {
  padding-top: 165px;
  padding-left: 60px;
  margin-bottom: 8rem;
  padding-right: 2.4rem;
}
#producto_container > :last-child > div {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  min-height: 520px;
}
.producto_container-reloj {
  /* margin: 150px 50px; */
  top: 150px;
  /* background-color: #decaff; */
  position: sticky;
  margin-bottom: 7rem;
}
#producto_container > :last-child > div > h1 {
  font-size: 80px;
  margin-bottom: 40px;
}
#producto_container > :last-child > div > p {
  font-size: 24px;
  font-weight: 100;
  line-height: 37px;
  margin-bottom: 15px;
}

#producto_container > :last-child > div > span {
  font-size: 30px;
  font-weight: 600;
  color: #2f80ed;
}

.producto_container-img {
  width: 100%;
}
.producto_container-reloj > ul {
  display: flex;
  margin-top: 1.6rem;
}
.producto_container-reloj > ul > li {
  display: inline;
  margin-right: 1.6rem;
  border-radius: 0.8rem;
  overflow: hidden;
}
.producto_container-reloj > ul > li > img {
  width: 65px;
}

.producto_container-colors {
  margin-top: 20px;
  margin-bottom: 20px;
}
.producto_container-colors > ul > li {
  position: relative;
  display: inline-block;
  height: 42px;
  width: 42px;
  border: 1px solid #bfbfbf;
  cursor: pointer;
  margin-right: 5px;
  border-radius: 12%;
}

.producto_container-colors > ul {
  margin-top: 10px;
}
.producto_container-colors .selected > img {
  opacity: 1;
}
.producto_container-colors > ul > li:nth-child(1) {
  background-color: black;
}
.producto_container-colors > ul > li:nth-child(2) {
  background-color: #123772;
}
.producto_container-colors > ul > li:nth-child(3) {
  background-color: white;
}
.producto_container-colors > ul > li:nth-child(4) {
  background-color: #FFA1C6;
}
.producto_container-quantity > span {
  font-size: 20px;
  font-weight: 100;
}
.producto_container-quantity > ul {
  margin-top: 10px;
  margin-bottom: 35px;
}
#number_car{
  display: inline-block;
  width: 40px;
  text-align: center;
}
.producto_container-quantity > ul > li {
  display: inline;
}
.show_mobile {
  display: none;
}
.producto_container-quantity > ul > li:nth-child(2) {
  margin: 0px 5px;
  font-size: 20px;
}
.plusLesButtons {
  background-color: #2f80ed;
  border: none;
  width: 42px;
  font-size: 20px;
  height: 42px;
  border-radius: 12%;
  cursor: pointer;
  color: #fff;
  align-items: center;
  outline: none;
}
.largeButtonOrder {
  outline: none;
  margin-top: auto;
  border: none;
  border-radius: 0.8rem;
  width: 100%;
  font-size: 20px;
  font-weight: 600;
  height: 45px;
  background-color: #2f80ed;
  color: #fff;
  cursor: pointer;
}
/* CONTACT SECTION*/
.special_container > p {
  text-align: center;
  font-size: 36px;
  margin-bottom: 50px;
}
.form--disabled{
  position: relative;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
.forms textarea {
  width: 100%;
  height: 200px;
  resize: none;
  padding: 10px;
  outline: none;
  border: 1px solid #c8c8c8;
  font-size: 15px;
}
.forms select {
  width: 100%;
  height: 40px;
  font-size: 15px;
  border: 1px solid #c8c8c8;
}
.forms select:disabled {
  border: 1px solid #c8c8c8;
  background-color: #fafafa;
  opacity: 1;
}
.forms input:disabled {
  border: 1px solid #c8c8c8;
}
.forms select:valid {
  border: 1px solid #2f80ed;
}
.forms select:focus:invalid {
  border: 1px solid red;
}
.forms select:focus:invalid > option:first-child {
  color: red;
}


.contact_section-list {
  margin-top: 50px;
}
.contact_section-list > li > img {
  position: absolute;
  left: 0;
  width: 20px;
  top: 1px;
}
.list-right > li > img {
  left: unset;
  top: 2px;
  right: 0;
}
.contact_section-list > li {
  position: relative;
  margin-bottom: 15px;
  padding: 0 30px;
  font-size: 18px;
}
.list-right > li {
  text-align: right;
}
.contact_section-list > :last-child {
  margin-bottom: 0;
}
/* LOGIN */
.special_container {
  position: relative;
  padding: 200px 0;
  margin: 0 auto;
  width: 50%;
}

.special_container > h1 {
  text-align: center;
  font-size: 56px;
}

.forms_checkout-email > div {
  position: relative;
}
.forms-fluid {
  width: 100% !important;
}
#forms_checkout .modal .forms,
.forms {
  position: relative;
  margin: 0 auto;
  width: 60%;
  padding-top: 16px;
}
.forms input:focus,
.forms_checkout-email input:focus {
  border: 1px solid #2f80ed;
}
.forms input,
.forms_checkout-email input {
  width: 100%;
  height: 40px;
  padding: 0 10px;
  border: 1px solid #c8c8c8;
  outline: none;
  font-size: 15px;
}

.forms input + label,
.forms textarea + label,
.forms_checkout-email input + label {
  font-size: 16px;
  display: block;
  pointer-events: none;
  position: absolute;
  top: 10px;
  left: 10px;
}

.forms input + label::before,
.forms textarea + label::before,
.forms_checkout-email input + label::before {
  content: attr(placeholder);
  color: transparent;
  display: inline-block;
  transition: all 0.3s ease-in-out;
  font-weight: 600;
}
.forms input:focus + label::before,
.forms_checkout-email input:not(:placeholder-shown):valid + label::before,
.forms_checkout-email input:not(:placeholder-shown) + label::before,
.forms input:not(:placeholder-shown):valid + label::before,
.forms input:not(:placeholder-shown) + label::before,
.forms textarea:focus + label::before,
.forms textarea:not(:placeholder-shown):valid + label::before,
.forms textarea:not(:placeholder-shown) + label::before {
  color: #2f80ed;
  transform: translate(0, -2em) scale(0.9, 0.9);
}
.forms input:disabled + label::before,
.forms textarea:disabled + label::before{
  color: #797979;
  transform: translate(0, -2em) scale(0.9, 0.9);
}

.forms input:focus::placeholder,
.forms textarea:focus::placeholder,
.forms_checkout-email input:focus::placeholder {
  transition: all 0.2s;
  color: transparent;
}
.forms input:focus:invalid,
.forms textarea:focus:invalid,
.forms_checkout-email input:focus:invalid {
  border: 1px solid red;
}
.forms input:not(:placeholder-shown):valid,
.forms textarea:not(:placeholder-shown):valid,
.forms_checkout-email input:not(:placeholder-shown):valid {
  border: 1px solid #2f80ed;
}
.forms input:not(:placeholder-shown):invalid + label::before,
.forms textarea:not(:placeholder-shown):invalid + label::before,
.forms_checkout-email input:not(:placeholder-shown):invalid + label::before {
  color: red;
}
.forms input:not(:placeholder-shown):invalid,
.forms textarea:not(:placeholder-shown):invalid,
.forms_checkout-email input:not(:placeholder-shown):invalid {
  border: 1px solid red;
}
.forms input:valid + label::before .forms a,
.forms textarea:valid + label::before .forms a {
  color: #2f80ed;
  font-size: 13px;
}
.forms input:focus:invalid + label::before,
.forms textarea:focus:invalid + label::before,
.forms_checkout-email input:focus:invalid + label::before {
  color: red;
}

.forms > div {
  margin-bottom: 35px;
  display: flex;
}
.forms div {
  position: relative;
}
.forms > div.margin_reduce_for_form {
  margin-bottom: 15px;
}
.createAccountButton {
  text-align: center;
  width: 100%;
  display: block;
  height: 40px;
  padding-top: 15px;
}

/* Dashboard*/
#dashboard_container {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}
/*
  #dashboard_container > div {
    width: 50%;
  }*/

.color_background {
  display: inline-block;
  height: 18px;
  border: 1px solid gray;
  width: 18px;
  background-color: rgb(0, 0, 0);
}
#testimonials {
  background-color: #f7f7fb;
  padding: 70px 0;
}
#testimonials > :nth-child(1) {
  text-align: center;
  margin-bottom: 30px;
}
#testimonials > :nth-child(2) {
  display: flex;
  margin: 0 auto;
  width: 60%;
  text-align: center;
}
#testimonials > :nth-child(2) > div {
  position: relative;
  width: 25%;
}
#testimonials > :nth-child(2) img {
  width: 145px;
}
#testimonials > :nth-child(2) > div > h2 {
  position: relative;
}
/*#testimonials > :nth-child(2) > div > h2::after {
    content: '';
    position: absolute;
    bottom: -12px;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 2px solid #000;
    width: 50px;
  }
  */

#accordionQuestions {
  margin: 50px 0;
}
.card-custom {
  border: 1px solid #c8c8c8;
  margin-bottom: 20px;
}
.card-custom button {
  font-size: 24px;
  padding-right: 80px;
  width: 100%;
  text-align: left;
}
.card-custom h2 {
  position: relative;
}
.card-custom button::after {
  content: url('../assets/img/Vector.png');
  position: absolute;
  right: 30px;
  top: 8px;
  transform: rotate(0deg);
  transition: all 0.3s;
}
.card-custom .btn.collapsed::after {
  transform: rotate(180deg);
}
.card-custom .card-body > p {
  font-size: 18px;
  margin: 0;
}

#text_about {
  margin: 50px 0;
}
#text_about > p {
  font-size: 30px;
  line-height: 45px;
}

.aboutLink {
  font-weight: 600;
  color: #2f80ed !important;
  font-size: 30px;
  line-height: 45px;
  transition: all ease-in-out 0.3s;
}

.aboutLink:hover{
  color: #013f9b !important;
}

#checkout_container {
  display: flex;
}
#checkout_container > div {
  padding-top: 80px;
  width: 50%;
}
#checkout_container > div:first-child {
  padding-left: 10%;
}
#car_item_checkout > div i {
  position: absolute;
  background: #2f80ed;
  top: 0;
  left: 80px;
  font-style: initial;
  border-radius: 100%;
  height: 20px;
  width: 20px;
  text-align: center;
  color: #fff;
  font-weight: bold;
  font-size: 10px;
  padding: 4px 0;
}
#car_item_checkout h4 {
  font-size: 2.2rem;
  margin-bottom: 5px;
}
#car_item_checkout span {
  color: #676767;
}
#car_item_checkout > div > div:first-child {
  width: 20%;
  min-width: 12rem;
}
#car_item_checkout > div > div:first-child img{
  object-fit: contain;
}
#car_item_checkout > div > div:last-child {
  text-align: right;
}
#checkout_container > :nth-child(2) {
  background-color: #f7f7fb;
  padding-left: 75px;
  padding-right: 170px;
}
.checkout_container-image_logo {
  text-align: center;
}
.checkout_container-nav li {
  position: relative;
  display: inline-block;
  font-size: 2rem;
  font-weight: 600;
  margin-right: 40px;
  color: #676767;
}

.checkout_container-nav li::after {
  content: url(../assets/img/chevron-pointed-right.svg);
  position: absolute;
  right: -27px;
  top: 70%;
  transform: translateY(-50%);
  color: #2f80ed;
}
.checkout_container-nav > ul {
  display: flex;
  justify-content: flex-start;
  width: 88%;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
}
.checkout_container-nav li:last-child {
  margin-right: 0;
}
.checkout_container-nav li:last-child::after {
  content: '';
}
.checkout_container-nav {
  margin-top: 20px;
  margin-bottom: 53px;
}
.forms_checkout-information {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.forms_checkout-information > h5 {
  font-size: 18px;
}
#forms_checkout h4,
.forms_checkout-information > h3 {
  font-size: 24px;
}
.cost_container h5 {
  font-size: 19.5px;
}
.cost_container h1 {
  font-size: 24px;
}
#forms_checkout {
  margin: 0 auto;
  width: 88%;
}
.largeButtonOrder.diabled {
  background-color: #676767;
}

.btn_navbar {
  display: none;
}
.forms_checkout-email {
  margin-top: 36px;
  margin-bottom: 48px;
}
#forms_checkout .forms {
  width: 100%;
}
#car_item_checkout > div {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
}
#car_item_checkout > div > div {
  position: relative;
  width: 46.3333334%;
}
#car_item_checkout > div img {
  width: 140px;
  height: 80px;
}
.car_item_checkout-costs {
  margin-top: 20px;
  border-top: 1px solid #2f80ed;
  border-bottom: 1px solid #2f80ed;
}
.cost_container {
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
}
.checkout-label{
  font-size: 1.9rem;
  text-align: left;
  display: flex;
  align-items: center;
  position: relative;
  /* justify-content: center; */
}
.checkout-label-paragraph--hide{
  position: absolute;
  top: 100%;
  left: 0;
  font-size: 1.4rem;
  transform-origin: top center;
  transition: all cubic-bezier(0.6, -0.28, 0.735, 0.045) 0.2s;
  color: red;
}
.checkout-label-paragraph--showing{
  transform: scaleY(100%);
  color: #0dd0dc;
}
.tabs_checkout > div {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #c8c8c8;
  border-left: 1px solid #c8c8c8;
  border-right: 1px solid #c8c8c8;
  padding: 10px 30px;
}
.tabs_checkout > div:first-child {
  border: 1px solid #c8c8c8;
}
.tabs_checkout h4 {
  color: #676767;
}
.tabs_checkout p {
  padding: 0;
  margin: 0;
}
.tabs_checkout a {
  font-size: 1.4rem;
  font-weight: 600;
}
.shipping_method {
  margin-top: 100px;
}
.tabs_checkout > div > :nth-child(2) {
  width: 60%;
}

.tabs_checkout > div > :nth-child(3) {
  width: 10%;
}
.tabs_checkout > div > div:last-child {
  text-align: right;
}

.tabs_checkout.tab_form > div > :nth-child(1) {
  width: 5%;
}

.tabs_checkout.tab_form > div > :nth-child(2) {
  width: 80%;
}
.shipping_method > h4 {
  margin-bottom: 22px;
}

.unselectable *{
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  filter: opacity(50%);
  pointer-events: none
}
.selectable *{
  -webkit-touch-callout: initial;
  -webkit-user-select: initial;
  -khtml-user-select: initial;
  -moz-user-select: initial;
  -ms-user-select: initial;
  user-select: initial;
  filter: opacity(100%);
  pointer-events: initial
}

.button_form_shipping_container {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-top: 100px;
  margin-bottom: 203px;
}
.button_form_shipping_container > div {
  width: 47%;
}
#payment_section {
  position: relative;
  display: flex;
  flex-wrap: wrap;
}
.creditcardform{
  background-color: #F7F7FB;
}
#payment_section > div {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  border-right: 1px solid #c8c8c8;
  border-bottom: 1px solid #c8c8c8;
  border-left: 1px solid #c8c8c8;
  padding: 15px 30px;
}
#payment_section > div:first-of-type {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}
#payment_section > div:first-of-type > div:first-child{
  width: 150px;
  /* background-color: rebeccapurple; */
  display: flex;
  align-items: flex-start;
  font-size: 2rem;
  font-weight: 600;
}
#payment_section > div:first-of-type > div:last-child{
  display: flex;
  justify-content: flex-end;
}
#payment_section > div:last-of-type {
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}
#payment_section > div > div {
  width: 30%;
}
#payment_section > div > div:first-child {
  width: 10%;
  text-align: center;
}
#payment_section > div.unable-first-child-style > div:first-child {
  width: 100%;
  text-align: center;
  margin-bottom: 0;
}

#payment_section > div > div:nth-child(2) {
  padding-left: 20px;
  width: 70%;
}
#payment_section > div:first-child {
  border: 1px solid #c8c8c8;
}
#payment_section ul > li {
  display: inline-block;
  margin-right: 10px;
}
.payment_selected_img {
  position: absolute;
  right: 25px;
  opacity: 0;
}
.payment_selected_img.selected {
  opacity: 1;
  transition: opacity 0.5s;
}
.checkout_tab.active {
  color: #2f80ed;
}

#pay_success_continer {
  position: relative;
}
.pay_success-logo {
  text-align: center;
  padding: 120px 0;
}

.order {
  margin: 0 auto;
  width: 50%;
  min-width: 337px;
  max-width: 720px;
}
.tabs_card {
  position: relative;
  border: 1px solid #c8c8c8;
  display: flex;
  justify-content: space-between;
  padding: 15px 15px;
}
.card_black {
  color: #676767;
  font-weight: 600;
  margin-right: 4px;
}
.tabs_card-table {
  width: 100%;
}
.order_customer-name {
  font-size: 48px;
  color: #2f80ed;
}
.order_titles > h6 {
  color: #676767;
  margin-top: 30px;
  font-size: 18px;
}
.tabs_container {
  margin-top: 75px;
  margin-bottom: 100px;
}
.card-login {
  display: flex;
  align-items: center;
}
.card-login_image {
  background-color: #f7f7fb;
  border-radius: 10px;
  margin-right: 15px;
}
.card-login_text-container > span {
  color: #2f80ed;
  cursor: pointer;
  font-weight: 600;
}
.card-login_text-container > p {
  margin-bottom: 5px;
}
.accesories{
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  /* background-color: rgb(0, 255, 85); */
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
}

.accesories h1{
  width: 100%;
  margin-bottom: 1rem;
}
.accesories h2{
  width: 100%;
  color: #5b5b5b;
}
.accesories h2 span{
  font-size: 1.8rem;
  font-weight: 600;
  color: #2f80ed;
}

.accesories .accessory:last-of-type{
  margin-bottom: 4rem;
}
.accessory{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  border-radius: 1rem;
  outline: 1px solid #d4d4d4;
  width: calc(50% - 1rem);
  min-width: 23rem;
  max-width: 25rem;
  height: 7rem;
  padding: 1.6rem;
  margin-top: 1.6rem;
  position: relative;
  z-index: 2;
}

.accessory:hover{
  outline: 1px solid #6b6b6b;
}
.accessory:hover.accessory-selected{
  outline: 3px solid #2F80ED;
}
.accessory-selected{
  outline: 2px solid #2F80ED;
}

.accessory:nth-of-type(odd){
  margin-right: 1rem;
}

.accessory-data{
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* background-color: red; */
  width: 100%;
  height: 100%;
}

.accessory-radio{
  display: flex;
  width: 24px;
  height: 24px;
  min-width: 24px;
  max-width: 24px;
  background-color: #ffffff;
  border: 2px solid #BFBFBF;
  border-radius: 5px;
  margin-right: 1rem;
  position: relative;
  transition: all cubic-bezier(0.075, 0.82, 0.165, 1) 500ms;
  cursor: pointer;
}
.accessory-radio--active{
  background-color: #2F80ED;
  border: 2px solid #2F80ED;
}
.accessory-radio-img{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  transition: all cubic-bezier(0.175, 0.885, 0.32, 1.275) 550ms;
}
.accessory-radio-img--active{
  transform: translate(-50%, -50%) scale(1);
}
.accessory-name{
  /* width: 310px; */
  /* margin-right: auto; */
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 1.8rem;
}
.accessory-name span{
  display: block;
  font-size: 1.6rem;
  font-weight: 600;
  color: #2f80ed;
}
.accessory-img{
  display: flex;
  margin-left: 1rem;
  height: 100%;
  width: 30px;
  position: relative;
}
.accessory-img img{
  height: 100%;
  width: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.accessory-strap-color{
  display: flex;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  border: 1px solid #BFBFBF;
}
.product-gallery{
 display: flex;
 flex-wrap: wrap;
 width: 100%;
 max-width: 1174px;
 /* background-color: #c0ff33; */
 margin-left: auto;
 margin-right: auto;
 margin-bottom: 32rem;
}
.produc-photo{
  display: flex;
  position: relative;
  overflow: hidden;
  border-radius: 2.4rem;
}
.produc-photo img{
  width: 100%;
  height: auto;
}
.product-gallery .produc-photo:nth-child(1){
  width: calc(50% - 2rem);
  margin-right: 2rem;
}
.product-gallery .produc-photo:nth-child(2){
  width: calc(50% - 2rem);
  margin-left: 2rem;
}
.product-gallery .produc-photo:nth-child(3){
  margin-top: 4rem;
  width: 100%;
}
.product-text-box{
  color: #ffffff;
  font-size: 5rem;
  font-weight: 600;
  position: absolute;
  line-height: 6rem;
}
.product-gallery .produc-photo:nth-child(1) .product-text-box{
  right: 8rem;
  top: 3rem;
  color: #000000;
}
.product-gallery .produc-photo:nth-child(2) .product-text-box{
  left: 8rem;
  top: 3rem;
}
.product-gallery .produc-photo:nth-child(3) .product-text-box{
  left: 36%;
  bottom: 6%;
}
